import * as PIXI from 'pixi.js';

class HealthBar extends PIXI.Container {
    constructor(totalHealth) {
        super();
        this.totalHealth = totalHealth; // Initialize totalHealth
        this.currentHealth = totalHealth; // Track current health
        this.setupHealthBar();
    }

    setupHealthBar() {
        // Load the textures
        const overlayTexture = PIXI.Texture.from('assets/battle/health-bar-overlay.png');
        const fillingTexture = PIXI.Texture.from('assets/battle/health-bar-filling.png');

        // Create sprites for each texture
        this.overlaySprite = new PIXI.Sprite(overlayTexture);
        this.fillingSprite = new PIXI.Sprite(fillingTexture);

        // Set the scale for both sprites
        this.overlaySprite.scale.x = this.overlaySprite.scale.y = 0.2;
        this.fillingSprite.scale.x = this.fillingSprite.scale.y = 0.2;

        // Add the filling sprite first, then the overlay
        this.addChild(this.fillingSprite);
        this.addChild(this.overlaySprite);

        // Initialize mask for the filling sprite
        this.fillingMask = new PIXI.Graphics().beginFill(0xffffff).drawRect(0, 0, this.fillingSprite.width, this.fillingSprite.height).endFill();
        this.fillingSprite.mask = this.fillingMask;
        this.addChild(this.fillingMask);
    }

    setHealth(newHealth) {
        this.currentHealth = newHealth;
        this.animateHealthChange();
    }

    animateHealthChange() {
        const newWidth = (this.currentHealth / this.totalHealth) * this.fillingSprite.width;
        const speed = 0.25; // Adjust speed as needed
        const threshold = 0.5; // Threshold to stop the animation

        const animation = (delta) => {
            const distance = newWidth - this.fillingMask.width;
            if (Math.abs(distance) < threshold) {
                this.fillingMask.width = newWidth; // Snap to final width
                PIXI.Ticker.shared.remove(animation); // Stop the animation
            } else {
                // Increment or decrement the width
                this.fillingMask.width += distance > 0 ? Math.min(speed * delta, distance) : Math.max(-speed * delta, distance);
            }
        };

        PIXI.Ticker.shared.add(animation);
    }
}

export default HealthBar;
