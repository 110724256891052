import React, { useState, useEffect } from 'react';

function VideoComponent() {
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerWidth * (9 / 16) });

    useEffect(() => {
        const handleResize = () => {
            let scaleMultiplier = (window.innerWidth < 800) ? 1: 0.5;
            // Set width to 100% of the container width and height maintaining 16:9 aspect ratio
            // setDimensions({ width: window.innerWidth * .5, height: window.innerWidth * (9 / 16) * .5 });
            setDimensions({ width: window.innerWidth * scaleMultiplier, height: window.innerWidth * (9 / 16) * scaleMultiplier });
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <iframe
                width={dimensions.width}
                height={dimensions.height}
                src="https://www.youtube.com/embed/h1cckm5UNFU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube Video"
                style={{ maxWidth: '100%' }} // Ensure it doesn't overflow on smaller screens
            ></iframe>
        </div>
    );
}

export default VideoComponent;
