class CreatureStorage {

    /**
     * Sets the createdCreature flag in local storage.
     * @param {boolean} value - The boolean value indicating if a creature has been created.
     */
    static setCreatedCreature(value) {
        localStorage.setItem('createdCreature', JSON.stringify(value));
    }

    /**
     * Gets the createdCreature flag from local storage.
     * @returns {boolean} - The value of the createdCreature flag.
     */
    static getCreatedCreature() {
        const value = localStorage.getItem('createdCreature');
        return value === null ? false : JSON.parse(value);
    }

    /**
     * Sets the foughtRival flag in local storage.
     * @param {boolean} value - The boolean value indicating if a rival has been fought.
     */
    static setFoughtRival(value) {
        localStorage.setItem('foughtRival', JSON.stringify(value));
    }

    /**
     * Gets the foughtRival flag from local storage.
     * @returns {boolean} - The value of the foughtRival flag.
     */
    static getFoughtRival() {
        const value = localStorage.getItem('foughtRival');
        return value === null ? false : JSON.parse(value);
    }

    /**
     * Sets the JWT token in local storage.
     * @param {string} token - The JWT token to be stored.
     */
    static setToken(token) {
        localStorage.setItem('jwtToken', token);
    }

    /**
     * Gets the JWT token from local storage.
     * @returns {string|null} - The JWT token, or null if not set.
     */
    static getToken() {
        return localStorage.getItem('jwtToken');
    }

    /**
     * Sets a flag in local storage indicating that the popup has been shown.
     * @param {boolean} showed - The value indicating whether the popup has been shown.
     */
    static setShowedPopup(showed) {
        localStorage.setItem('showedPopup', showed.toString());
    }

    /**
     * Gets the flag from local storage that indicates whether the popup has been shown.
     * @returns {boolean} - True if the popup has been shown, false otherwise.
     */
    static getShowedPopup() {
        const showed = localStorage.getItem('showedPopup');
        return showed === 'true';
    }
}

export default CreatureStorage;
