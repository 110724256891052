import React from 'react';
import './AirdropDialog.css';
import airdropIcon from '../images/airdrop-icon.png';

const AirdropDialog = ({ isOpen, onClose, numReferrals, referralUrl }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (event) => {
        // If the direct target of the click is the overlay, close the dialog
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const getReferralText = () => {
        return encodeURIComponent(`I joined Creatures, a hot new MMORPG funded by @MeritCircle_IO. You use AI to create and battle unique creatures! 🐉 Right now, they're offering free mints and an airdrop. Use my referral link to grab 50,000 coins for the airdrop: ${referralUrl} 🚀 #Gaming #Crypto`);
    }

    return (
        <div className="airdrop-dialog-overlay" onClick={handleOverlayClick}>
            <div className="airdrop-dialog" onClick={(e) => e.stopPropagation()}>
                <div className="airdrop-dialog-close" onClick={onClose}>&times;</div>
                <h1>Win Coins</h1>
                <div className={'airdrop-dialog-body-cols'}>
                    <div>
                        <img
                            width={200}
                            src={airdropIcon}
                        />
                    </div>
                    <div className={'airdrop-dialog-instructions'}>
                        <p>For a limited time, you can earn coins for a future airdrop!</p>
                        <p>Each friend that you get to play Creatures gets you and them 50,000 coins.</p>
                    </div>
                </div>

                <a
                    className="airdrop-dialog-tweet-btn"
                    href={`https://twitter.com/intent/tweet?text=${getReferralText()}`}
                    target={'_blank'}
                >
                    Tweet
                </a>
                <div className={'referrals-holder'}>Referrals: <b>{numReferrals}</b></div>
                <div>
                    <h3>Reward Tiers</h3>
                    <table className={'rewards-tier'}>
                        <tr>
                            <th># Referrals</th>
                            <th>Reward</th>
                        </tr>
                        <tr>
                            <td>1-9 referrals</td>
                            <td>50,000 coins each</td>
                        </tr>
                        <tr>
                            <td>10-29 referrals</td>
                            <td>80,000 each</td>
                        </tr>
                        <tr>
                            <td>30+ referrals</td>
                            <td>100,000 each</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AirdropDialog;
