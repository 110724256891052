import Global from "./Global";

export default class Socket {
    constructor(onConnected) {
        this.reconnecting = false;
        this.onMessage = this.onMessage.bind(this);
        this.onDisconnect = this.onDisconnect.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onConnected = onConnected;
        this.initSocket();
    }

    initSocket() {
        const WS_ENDPOINT = window.location.href.indexOf('creaturesai.online') !== -1
            ? 'wss://api.creaturesai.online/websocket'
            : `ws://${window.location.hostname}:3001`;

        this.ws = new WebSocket(WS_ENDPOINT);
        this.ws.onmessage = this.onMessage;
        this.ws.onopen = this.onOpen;
        this.ws.onerror = (e) => console.error('WebSocket error:', e);
        this.ws.onclose = this.onDisconnect;
    }

    onOpen() {
        if (this.reconnecting) {
            Global.game.socketReconnected();
            this.reconnecting = false;
        } else {
            if (this.onConnected) this.onConnected();
        }
    }

    onDisconnect(e) {
        this.reconnecting = true;
        this.initSocket();
    }

    joinMap(mapId, x, y, dir) {
        this.ws.send(JSON.stringify({
            action: "joinMap",
            mapId: mapId,
            x: x,
            y: y,
            dir: dir
        }));
    }

    leaveMap(mapId) {
        this.ws.send(JSON.stringify({
            action: "leaveMap",
            mapId: mapId
        }));
    }

    updateCharState(x, y, dir, counter) {
        this.ws.send(JSON.stringify({
            action: "charStateUpdate",
            x: x,
            y: y,
            dir: dir,
            counter: counter
        }));
    }

    sendChatMessage(message) {
        this.ws.send(JSON.stringify({
            action: "chat",
            message: message
        }));
    }

    onMessage(event) {
        const data = JSON.parse(event.data);

        if (data.action !== 'ping')
            console.log('Received message:', data);

        switch (data.action) {
            case "charStateUpdate":
                this.handleCharStateUpdate(data);
                break;
            case "playerJoinMap":
                this.handlePlayerJoinMap(data);
                break;
            case "playerLeaveMap":
                this.handlePlayerLeaveMap(data);
                break;
            case "broadcastChat":
                this.handleBroadcastChat(data);
                break;
            case "CurrentWorldStatus":
                this.handleCurrentWorldStatus(data);
                break;
            case "chat":
                this.handleBroadcastChat(data);
                break;
            case "ping":
                this.ping();
                break;
        }
    }

    handleCharStateUpdate(data) {
        console.log(`Character state updated for ${data.playerId}:`, data);
        // Implement further logic as needed
    }

    handlePlayerJoinMap(data) {
        console.log(`${data.playerId} joined map ${data.mapId}`);
        // Implement further logic as needed
    }

    handlePlayerLeaveMap(data) {
        console.log(`${data.playerId} left map ${data.mapId}`);
        // Implement further logic as needed
    }

    handleBroadcastChat(data) {
        console.log(`Chat from ${data.playerId}: ${data.message}`);
        // Implement further logic as needed
    }

    handleCurrentWorldStatus(data) {
        console.log('Current world status:', data.players);
        // Implement further logic as needed
    }

    ping() {
        this.ws.send(JSON.stringify({
            action: "ping"
        }));
    }

    /**
     * Cleans up resources and listeners associated with the WebSocket.
     */
    destroy() {
        if (this.ws) {
            // Remove event listeners to prevent memory leaks
            this.ws.removeEventListener('message', this.onMessage);
            this.ws.removeEventListener('close', this.onDisconnect);
            this.ws.removeEventListener('open', this.onOpen);
 
            // Close the WebSocket connection if it's open
            if (this.ws.readyState === WebSocket.OPEN) {
                this.ws.close();
            }

            // Nullify the WebSocket reference
            this.ws = null;
        }

        // Nullify the references to callback functions
        this.onMessage = null;
        this.onDisconnect = null;
        this.onOpen = null;
        this.onConnected = null;
    }
}
