import * as PIXI from 'pixi.js';
import Dialog from './Dialog';

class DialogChain extends PIXI.Container {
    /**
     * Creates a DialogChain instance.
     * @param {string[]} dialogTexts - Array of strings for dialogs.
     * @param {boolean} autoContinue - Whether to automatically continue to the next dialog.
     */
    constructor(dialogTexts, autoContinue) {
        super();
        this.dialogTexts = dialogTexts;
        this.autoContinue = autoContinue;
        this.currentDialogIndex = 0;
        this.onFinish = null; // Callback when the chain finishes
        this.autoContinueTimeout = null; // Timeout for auto-continuation

        this.showNextDialog();
    }

    /**
     * Shows the next dialog in the sequence.
     * @private
     */
    showNextDialog() {
        // Clear any existing auto-continue timeout
        if (this.autoContinueTimeout) {
            clearTimeout(this.autoContinueTimeout);
            this.autoContinueTimeout = null;
        }

        // Remove the current dialog if it exists
        if (this.currentDialog) {
            this.removeChild(this.currentDialog);
        }

        // Check if there are more dialogs to show
        if (this.currentDialogIndex < this.dialogTexts.length) {
            const text = this.dialogTexts[this.currentDialogIndex];
            const isLastDialog = this.currentDialogIndex === this.dialogTexts.length - 1;

            // Decide whether to show next/back buttons based on autoContinue
            const showButtons = !this.autoContinue;
            const dialog = new Dialog(text, showButtons);

            dialog.onNext = () => {
                if (isLastDialog && this.onFinish) {
                    this.onFinish();
                } else {
                    this.currentDialogIndex++;
                    this.showNextDialog();
                }
            };

            this.currentDialog = dialog;
            this.addChild(dialog);

            // Set auto-continue if enabled
            if (this.autoContinue) {
                this.autoContinueTimeout = setTimeout(() => {
                    if (!isLastDialog) {
                        this.currentDialogIndex++;
                        this.showNextDialog();
                    } else if (this.onFinish) {
                        this.onFinish();
                    }
                }, 5000); // Continue after 5 seconds
            }
        }
    }
}

export default DialogChain;
