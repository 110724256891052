import React from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import LoginPage from "./pages/LoginPage";
import CreaturePage from "./pages/CreaturePage";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route exact path="/" element={<HomePage />}/>
                    <Route path="/game" element={<GamePage />}/>
                    <Route path="/login" element={<LoginPage />}/>
                    <Route path="/creature/:id" element={<CreaturePage />}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
