import * as PIXI from 'pixi.js';
import Global from "../../../Global";
import AssetLoader from '../../AssetLoader';
import CreatureFrame from "../../battle/components/CreatureFrame";

/**
 * Converts a creature ID to a referral code.
 * @param {number} creatureId - The creature ID to convert.
 * @returns {string} The generated referral code.
 */
export const getEncodedId = (creatureId) => {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let referralCode = '';
    while (creatureId > 0) {
        referralCode = ALPHABET[creatureId % ALPHABET.length] + referralCode;
        creatureId = Math.floor(creatureId / ALPHABET.length);
    }
    return referralCode;
};



/**
 * The ViewCreatureDialog class extends PIXI.Container to create a dialog for viewing creatures.
 */
class ViewCreatureDialog extends PIXI.Container {
    /**
     * Constructs a new ViewCreatureDialog instance.
     * @param {Object[]} imageUrls - An array of image URLs for the creatures.
     * @param {Function} onClose
     */
    constructor(imageUrls) {
        super();
        this.imageUrls = imageUrls.map((c) => c.url);
        this.currentIndex = imageUrls.length-1;
        this.creatureSprites = [];
        this.onComplete = null;

        this.loadBackground();
        this.addTitle(); // Call the method to add the title

        const nameWithUrls = imageUrls.map(
            obj => ({
               url: obj.url,
               name: obj.url.split('/').pop()
            })
        )
        AssetLoader.addAndLoadNewAssets(nameWithUrls, this.onImagesLoaded.bind(this));

        // center dialog
        this.x = (Global.width - this.width) / 2;
        this.y = (Global.height - this.height) / 2 - 40;
    }

    onImagesLoaded() {
        if (this.imageUrls.length > 1) this.addArrows();
        this.loadCreatureImages();
        this.addContinueButton();
        this.addShareButton();
    }

    /**
     * Loads the background image and adds it as a sprite.
     */
    loadBackground() {
        // Re-use the loadBackground from BigChoicePicker
        const bgTexture = PIXI.Texture.from('assets/ui/big-menu-empty.png');
        const bgSprite = new PIXI.Sprite(bgTexture);
        this.addChild(bgSprite);

        bgSprite.scale.x = bgSprite.scale.y = 0.55;
    }

    /**
     * Adds left and right arrow buttons to cycle through creatures.
     */
    addArrows() {
        const leftArrow = this.createArrow('<', () => this.cycleCreature(-1));
        const rightArrow = this.createArrow('>', () => this.cycleCreature(1));

        leftArrow.x = 50;
        rightArrow.x = this.width - rightArrow.width - 50;

        leftArrow.y = rightArrow.y = (this.height - leftArrow.height) / 2;
        this.addChild(leftArrow);
        this.addChild(rightArrow);
    }

    /**
     * Creates an arrow button.
     * @param {string} direction - The text for the arrow.
     * @param {Function} onClick - The callback function when the arrow is clicked.
     * @return {PIXI.Text} The created arrow button.
     */
    createArrow(direction, onClick) {
        // Re-use the createButton from BigChoicePicker
        const arrow = new PIXI.Text(direction, {
            fontFamily: 'Press Start 2P',
            fontSize: 36,
            fill: 0xFFFFFF
        });
        arrow.interactive = true;
        arrow.buttonMode = true;
        arrow.on('pointerdown', onClick);
        return arrow;
    }

    /**
     * Cycles to the next or previous creature image.
     * @param {number} direction - The direction to cycle, positive for next, negative for previous.
     */
    cycleCreature(direction) {
        Global.game.sound.playSFX('dialog-continue');
        this.currentIndex += direction;
        if (this.currentIndex < 0) this.currentIndex = this.imageUrls.length - 1;
        if (this.currentIndex >= this.imageUrls.length) this.currentIndex = 0;

        this.creatureSprites.forEach((sprite, index) => {
            sprite.visible = index === this.currentIndex;
        });
    }

    /**
     * Loads creature images and adds them as sprites.
     */
    loadCreatureImages() {
        this.imageUrls.forEach((url, index) => {
            const sprite = new CreatureFrame(url);

            this.creatureSprites.push(sprite);
            this.addChild(sprite);

            // Center the sprite
            sprite.x = this.width / 4 - 20;
            sprite.y = this.height / 4 - 30;
            sprite.scale.x = sprite.scale.y = 1.2;

            sprite.visible = index === this.currentIndex; // Only the current image is visible
        });
    }

    /**
     * Adds a continue button at the bottom of the dialog.
     */
    addContinueButton() {
        const continueHandler = () => {
            Global.game.sound.playSFX('dialog-continue');
            this.onComplete();
        }
        const onComplete = continueHandler.bind(this);
        const continueButton = this.createButton('Continue', onComplete);
        continueButton.x = (this.width - continueButton.width) / 2 + 80;
        continueButton.y = this.height - continueButton.height - 30;
        this.addChild(continueButton);
    }

    /**
     * Adds a share button at the bottom of the dialog.
     */
    addShareButton() {
        const shareHandler = () => {
            Global.game.sound.playSFX('dialog-continue');
            const creatureId = parseInt(this.imageUrls[this.currentIndex].split('/').pop().split('-').pop().split('.')[0]);
            const encodedCreatureId = getEncodedId(creatureId);

            const getReferralText = () => {
                return `🌟 Witness a creature like no other! 🐉 I just spawned a unique AI-generated Creature NFT with a FREE MINT – for a limited time only! See what I made and claim your own before it's too late https://creaturesai.online/creature/${encodedCreatureId} $BEAM `;
            };

            window.open(`https://twitter.com/intent/tweet?text=${getReferralText()}`)
        }
        const onShare = shareHandler.bind(this);
        const shareBtn = this.createButton('Share', onShare);
        shareBtn.x = (this.width - shareBtn.width) / 2 - 100;
        shareBtn.y = this.height - shareBtn.height - 30;
        this.addChild(shareBtn);
    }

    /**
     * Creates a text button.
     * @param {string} buttonText - The text for the button.
     * @param {Function} onClick - The callback function when the button is clicked.
     * @return {PIXI.Text} The created button.
     */
    createButton(buttonText, onClick) {
        // Re-use the createButton from BigChoicePicker with added onClick parameter
        const button = new PIXI.Text(buttonText, {
            fontFamily: 'Press Start 2P',
            fontSize: 16,
            fill: 0xFFFFFF
        });
        button.interactive = true;
        button.buttonMode = true;
        button.on('pointerdown', onClick);
        return button;
    }

    /**
     * Adds a title at the top of the dialog.
     */
    addTitle() {
        const title = new PIXI.Text('My Creatures', {
            fontFamily: 'Press Start 2P',
            fontSize: 20,
            fill: 0xFFFFFF
        });

        title.anchor.set(0.5);
        title.x = this.width / 2;
        title.y = 50; // Adjust this value as needed for positioning
        this.addChild(title);
    }
}

export default ViewCreatureDialog;
