import React, {useEffect} from 'react';
import './ImageGrid.css';

// Explicitly import each image
import creature1 from '../images/creatures/creature1.png';
import creature3 from '../images/creatures/creature3.png';
import creature4 from '../images/creatures/creature4.png';
import creature5 from '../images/creatures/creature5.png';
import creature6 from '../images/creatures/creature6.png';
import creature7 from '../images/creatures/creature7.png';
import creature8 from '../images/creatures/creature8.png';
import creature9 from '../images/creatures/creature9.png';
import creature11 from '../images/creatures/creature11.png';
import creature12 from '../images/creatures/creature12.png';
import creature13 from '../images/creatures/creature13.png';
import creature15 from '../images/creatures/creature15.png';
import creature16 from '../images/creatures/creature16.png';
import creature17 from '../images/creatures/creature17.png';
import creature18 from '../images/creatures/creature18.png';
import creature19 from '../images/creatures/creature19.png';
import creature21 from '../images/creatures/creature21.png';
import creature22 from '../images/creatures/creature22.png';
import creature23 from '../images/creatures/creature23.png';
import creature24 from '../images/creatures/creature24.png';

const ImageGrid = () => {
    const images = [
        creature7, creature22, creature4, creature11, creature9,
        creature8, creature1, creature6, creature5, creature12,
        creature13, creature15, creature16, creature17, creature18,
        creature19, creature21, creature3, creature23, creature24,
    ];

    useEffect(() => {
        const handleScroll = () => {
            const images = document.querySelectorAll('.grid-image');

            images.forEach(image => {
                const imageTop = image.getBoundingClientRect().top;
                const isVisible = imageTop < window.innerHeight;

                if (isVisible) {
                    image.classList.add('fade-in');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        // Trigger the scroll handler once on component mount to check initial position
        handleScroll();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="image-grid">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`Creature ${index + 1}`} className="grid-image"/>
            ))}
        </div>
    );
};

export default ImageGrid;
