import mixpanel from 'mixpanel-browser';

export default class Telemetry {
    static init() {
        mixpanel.init('eb3eb5581f12e3fb809189f1ddb44ee4');
    }

    static trackEvent(event, data) {
        if (window.location.hostname !== 'localhost') {
            mixpanel.track(event, data);
        }
    }
}
