import * as PIXI from 'pixi.js';
import Dialog from "../components/Dialog";
import Global from "../../../Global";
import Map from "../../maps/Map";
import CreatureStorage from "../../CreatureStorage";
import DialogChain from "../components/DialogChain";
import Telemetry from "../../../Telemetry";
import CreatureAPI from "../../../API";
import CreatureOptionPicker from "../components/CreatureOptionPicker";

class CreateCreatureFlow extends PIXI.Container {
    constructor() {
        super();

        this.onComplete = null; // onComplete(element, species, size); called when flow is complete
        this.element = null;
        this.species = null;
        this.size = null;
        this.available = {};

        const hasCreatedCreatureAlready = CreatureStorage.getCreatedCreature();

        if (hasCreatedCreatureAlready) {
            this.currentChild = new Dialog('Let\'s create another creature!', true, hasCreatedCreatureAlready);
            this.currentChild.onNext = this.loadAvailable.bind(this);
            this.currentChild.onBack = Global.game.gotoMap.bind(Global.game, Map.MapCodes.HOME);
        } else {
            this.currentChild = new DialogChain(
                [
                    'Welcome to Creatures, a world with\nadvanced genomics and Artifical\nIntelligence.',
                    'Here you can describe your\nideal creature\'s characteristics.\nAnd we\'ll bring it to life!',
                    'Let\'s get started!'
                ]);
            this.currentChild.onFinish = this.loadAvailable.bind(this);
        }

        this.addChild(this.currentChild);
    }

    /**
     * Load the available amounts of each type
     */
    async loadAvailable() {
        this.removeChild(this.currentChild);
        try {
            this.available = await CreatureAPI.getCreatureVariationCounts();
        } catch (err) {
            console.log(err);
        }

        this.showPickElement();
    }

    showPickElement() {
        this.currentChild = new CreatureOptionPicker(
            'Pick an element',
            ['Fire', 'Water', 'Electricity', 'Wind'],
            false,
            false,
            this.available
        );
        this.addChild(this.currentChild);
        this.currentChild.onChoiceSelected = this.onPickOption.bind(this, 'element');
        Telemetry.trackEvent('View pick element');
    }

    /**
     *
     * @param option {String} i.e element, size
     * @param choice {String}
     */
    onPickOption(option, choice) {
        Global.game.sound.playSFX('dialog-continue');
        this.removeChild(this.currentChild);

        this[option] = choice;

        if (option === 'element') {
            this.showPickSpecies();
        } else if (option === 'species') {
            this.showPickSize()
        } else if (option === 'size') {
            if(this.onComplete) {
                this.onComplete(this.element, this.species, this.size);
            }
        }

        Telemetry.trackEvent('Pick creature option', {option, choice});
    }

    showPickSize() {
        this.removeChild(this.currentChild);
        this.currentChild = new CreatureOptionPicker(
            'Pick a size',
            ['Small', 'Medium', 'Large'],
            true,
            false,
            this.available,
            this.element+this.species
        );
        this.addChild(this.currentChild);
        this.currentChild.onChoiceSelected = this.onPickOption.bind(this, 'size');
        this.currentChild.onBack = this.showPickSpecies.bind(this); // Go back to species selection
        Telemetry.trackEvent('View pick size');
    }

    showPickSpecies() {
        this.removeChild(this.currentChild);
        this.currentChild = new CreatureOptionPicker(
            'Pick a species',
            ['Dragon', 'Feline', 'Insect', 'Snake', 'Bear', 'Bird'],
            true,
            false,
            this.available,
            this.element
        );
        this.addChild(this.currentChild);
        this.currentChild.onChoiceSelected = this.onPickOption.bind(this, 'species');
        this.currentChild.onBack = this.showPickElement.bind(this); // Go back to element selection
        Telemetry.trackEvent('View pick species');
    }
}

export default CreateCreatureFlow;