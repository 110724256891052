import * as PIXI from 'pixi.js';

/**
 * Class representing an animated loading text using Pixi.js.
 */
class LoadingText extends PIXI.Text {
    /**
     * Creates an instance of LoadingText.
     */
    constructor() {
        const style = new PIXI.TextStyle({
            fill: '#ffffff', // White color
            fontSize: 24
        });

        super('Loading', style);

        this.dotCount = 0;
        this.frameCount = 0;
        this.framesPerDot = 30; // Number of frames to wait before adding a dot
        this.animationCallback = this.animateText.bind(this);
        this.setupAnimation();
    }

    /**
     * Sets up the loading text animation.
     */
    setupAnimation() {
        PIXI.Ticker.shared.add(this.animationCallback);
    }

    /**
     * Animates the text by updating the dot count.
     */
    animateText() {
        if (++this.frameCount >= this.framesPerDot) {
            this.frameCount = 0;
            this.dotCount = (this.dotCount + 1) % 4;
            this.text = `Loading${'.'.repeat(this.dotCount)}`;
        }
    }

    /**
     * Shows an error message.
     */
    showError() {
        // Stop the animation
        PIXI.Ticker.shared.remove(this.animationCallback);

        // Set the text to the error message
        this.text = "Something went wrong.\nPlease refresh the page.";
    }


    /**
     * Cleans up the resources used by the text animation.
     */
    destroy(options) {
        PIXI.Ticker.shared.remove(this.animationCallback);
        super.destroy(options);
    }
}

export default LoadingText;
