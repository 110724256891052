import * as PIXI from "pixi.js";
import Global from "../Global";

/**
 *
 * @param dir {String} down, up, left, right
 */
const getWalkingSprite = (dir) => {
    let walkingDown = [`assets/player/player_${dir}1.png`, `assets/player/player_${dir}2.png`, `assets/player/player_${dir}1.png`, `assets/player/player_${dir}3.png`];
    let walkingDownTextures = [];

    for (let i = 0; i < 4; i++) {
        let texture = PIXI.Texture.from(walkingDown[i]);
        texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
        walkingDownTextures.push(texture);
    }

    let animatedSprite = new PIXI.AnimatedSprite(walkingDownTextures);
    animatedSprite.animationSpeed = 0.1;

    return animatedSprite;
}

class Character extends PIXI.Container {
    constructor() {
        super();

        this.scale.set(2, 2);
        this.dir = "down";
        this.walkingSprites = {
            "down": getWalkingSprite("down"),
            "up": getWalkingSprite("up"),
            "left": getWalkingSprite("left"),
            "right": getWalkingSprite("right"),
        }
        this.prevSprite = null;
        this.isWalking = false;
        this.walkingSpeed = 4 * 1; // 4
        this.updateSprite();

        setInterval(this.onWalk.bind(this), 25);
    }

    setDir(dir) {
        this.dir = dir;
        this.isWalking = false;
        this.updateSprite();
    }

    onWalk() {
        if (!this.isWalking) {
            // console.log('not walking lol');
            return;
        }
        let newX = this.x;
        let newY = this.y;
        if (this.dir === "down") {
            newY = this.y + this.walkingSpeed;
        } else if (this.dir === "up") {
            newY = this.y - this.walkingSpeed;
        } else if (this.dir === "left") {
            newX = this.x - this.walkingSpeed;
        } else if (this.dir === "right") {
            newX = this.x + this.walkingSpeed;
        }

        if (Global.world.map.collidingWithRival(newX, newY, this.width/1.5, this.height)) {
            Global.game.fightWithRival();
            return;
        }

        if (!Global.world.map.collidesWithHitBoxes(newX, newY, this.width/1.5, this.height)){
            this.x = newX;
            this.y = newY;
        }
        
        const collidingPortalMapCode = Global.world.map.getCollidingPortalMapCode(this.x, this.y, this.width/1.5, this.height);
        if (collidingPortalMapCode != null){
            this.isWalking = false;
            this.updateSprite();
            Global.game.gotoMap(collidingPortalMapCode);
        }
    }

    updateSprite() {
        if (this.prevSprite) this.removeChild(this.prevSprite);
        this.prevSprite = this.walkingSprites[this.dir];
        this.addChild(this.prevSprite);

        if (this.isWalking) {
            this.prevSprite.gotoAndPlay(1);
        }
        else {
            this.prevSprite.gotoAndStop(0);
        }
    }

    moveUp() {
        if (this.dir !== "up" || !this.isWalking) {
            this.dir = "up";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveDown() {
        if (this.dir !== "down" || !this.isWalking) {
            this.dir = "down";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveLeft() {
        if (this.dir !== "left" || !this.isWalking) {
            this.dir = "left";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveRight() {
        if (this.dir !== "right" || !this.isWalking) {
            this.dir = "right";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    stopMoving() {
        this.isWalking = false;
        this.updateSprite();
    }
}

export default Character;