import * as PIXI from 'pixi.js';

class Spacebar extends PIXI.Container {
    constructor() {
        super();
        this.initSpacebar();
    }

    initSpacebar() {
        // Load the spacebar sprite
        const spacebarTexture = PIXI.Texture.from('assets/battle/spacebar.png');
        this.spacebarSprite = new PIXI.Sprite(spacebarTexture);

        // Set initial alpha and position
        this.spacebarSprite.alpha = 0.5;

        this.addChild(this.spacebarSprite);

        // Start the blinking animation
        this.startBlinking();
    }

    startBlinking() {
        const blinkSpeed = 0.05; // Adjust for faster/slower blinking
        let increasing = true; // Direction of alpha change

        this.blinkAnimation = (delta) => {
            if (increasing) {
                this.spacebarSprite.alpha += blinkSpeed * delta;
                if (this.spacebarSprite.alpha >= 1) {
                    increasing = false;
                }
            } else {
                this.spacebarSprite.alpha -= blinkSpeed * delta;
                if (this.spacebarSprite.alpha <= 0.5) {
                    increasing = true;
                }
            }
        };

        PIXI.Ticker.shared.add(this.blinkAnimation);
    }

    // Call this method to clean up
    destroy() {
        PIXI.Ticker.shared.remove(this.blinkAnimation);
        super.destroy();
    }
}

export default Spacebar;
