import * as PIXI from 'pixi.js';

class BattleBar extends PIXI.Container {
    /**
     * Constructs a BattleBar instance.
     * @param {string} type - The type of the bar, either 'attack' or 'defense'.
     */
    constructor(type) {
        super();

        this.oscillate = (delta) => {
            const speed = 15; // Adjust speed as needed
            if (!this.moving) {
                PIXI.Ticker.shared.remove(this.oscillate);
                return;
            }

            // Oscillate the width of the filling
            this.fillingMask.width += speed * delta * (this.fillingIncreasing ? 1 : -1);

            // Reverse direction at bounds
            if (this.fillingMask.width >= this.fillingSprite.width || this.fillingMask.width <= 0) {
                this.fillingIncreasing = !this.fillingIncreasing;
                this.fillingMask.width = Math.max(0, Math.min(this.fillingSprite.width, this.fillingMask.width + speed * delta * (this.fillingIncreasing ? 1 : -1)));
            }
        };
        this.type = type;
        this.setupBar();
        this.startMovement()
    }

    /**
     * Sets up the battle bar, including textures and masking.
     */
    setupBar() {
        // Determine the texture based on the type
        const typeTexture = PIXI.Texture.from(`assets/battle/battle-bar-${this.type === 'attack' ? 'sword' : 'shield'}.png`);
        const fillingTexture = PIXI.Texture.from('assets/battle/battle-bar-filling.png');
        const overlayTexture = PIXI.Texture.from('assets/battle/battle-bar-overlay.png');

        // Create sprites for each texture
        this.typeSprite = new PIXI.Sprite(typeTexture);
        this.fillingSprite = new PIXI.Sprite(fillingTexture);
        this.overlaySprite = new PIXI.Sprite(overlayTexture);

        // Set scale for the sprites
        // Adjust scale values as needed
        this.typeSprite.scale.set(0.3, 0.3);

        this.typeSprite.x = -this.typeSprite.width/2;
        this.typeSprite.y = -this.typeSprite.height/4 + 5;
        this.scale.x = this.scale.y = 0.8;

        // Add the sprites to the container
        this.addChild(this.fillingSprite);
        this.addChild(this.overlaySprite);
        this.addChild(this.typeSprite);

        // Initialize mask for the filling sprite
        this.fillingMask = new PIXI.Graphics()
            .beginFill(0xffffff)
            .drawRect(0, 0, this.fillingSprite.width, this.fillingSprite.height)
            .endFill();
        this.fillingSprite.mask = this.fillingMask;
        this.addChild(this.fillingMask);

        // Initial mask width is zero
        this.fillingMask.width = 0;

        // Movement control
        this.moving = false;
    }

    /**
     * Starts the oscillation of the bar filling.
     */
    startMovement() {
        this.moving = true;

        PIXI.Ticker.shared.add(this.oscillate);
        this.fillingIncreasing = true;
    }

    /**
     * Stops the oscillation of the bar filling.
     */
    stopMovement() {
        this.moving = false;
    }

    /**
     * Gets the current fill percentage of the bar.
     * @returns {number} The fill percentage (0 to 100).
     */
    getCurrentPerc() {
        // Calculate the fill ratio
        const fillRatio = this.fillingMask.width / this.fillingSprite.width;

        // Return the ratio as a percentage
        return fillRatio * 100;
    }

    destroy() {
        this.stopMovement();

        // Remove the ticker callback
        if (this.oscillate) {
            PIXI.Ticker.shared.remove(this.oscillate);
        }
        super.destroy();
    }
}

export default BattleBar;
