import * as PIXI from 'pixi.js';
import Battle from "./Battle";
import Utils from "../../Utils";
import Global from "../../Global";

class BattleController extends PIXI.Container {
    static BATTLE_STAGES = {
        YOUR_ATTACK: 0,
        OPP_DEFENSE: 1,
        OPP_ATTACK: 2,
        YOUR_DEFENSE: 3,
    };
    constructor(leftUrl, rightUrl) {
        super();

        this.currPhase = BattleController.BATTLE_STAGES.YOUR_ATTACK;
        this.myHealth = 1000;
        this.oppHealth = 1000;
        this.battle = new Battle(this.myHealth, this.oppHealth, leftUrl, rightUrl);
        this.myAttack = null;
        this.myDefense = null;
        this.oppAttack = null;
        this.oppDefense = null;
        this.oppAttackBar = null;
        this.myAttackBar = null;
        this.oppDefenseBar = null;
        this.myDefenseBar = null;
        this.onBattleComplete = null; // onBattleComplete(won:bool)
        this.keyProcessed = false;
        this.addChild(this.battle);
        this.battle.setupBattleDialog(this.startBattle.bind(this));
        // this.startBattle();
    }

    /**
     * Sets up the key listener.
     */
    setupKeyListener() {
        document.addEventListener('keydown', this.onKeyDown.bind(this));
    }

    onKeyDown(e) {
        if (e.code === 'Space') {
            if (this.keyProcessed) return; // Ignore if already processed

            if (
                this.currPhase === BattleController.BATTLE_STAGES.YOUR_ATTACK ||
                this.currPhase === BattleController.BATTLE_STAGES.YOUR_DEFENSE
            ) {
                this.keyProcessed = true;
                document.removeEventListener('keydown', this.onKeyDown); // only trigger event once
                this.continueBattle();
            }

        }
    }

    /**
     * Called at every step of the battle (your attack, opp defense, opp attack, etc)
     */
    async continueBattle() {
        if (this.currPhase === BattleController.BATTLE_STAGES.YOUR_ATTACK) {
            // next phase
            this.currPhase = BattleController.BATTLE_STAGES.OPP_DEFENSE;
            // stop our bar
            this.myAttackBar.stopMovement();
            this.myAttack = this.myAttackBar.getCurrentPerc();
            this.battle.setSpaceBarVisibility(false);
            this.keyProcessed = false;

            // handle CPU
            this.startDefense('right');
            setTimeout(() => {
                this.continueBattle();
            }, Math.random()*1000+1000);
        } else if (this.currPhase === BattleController.BATTLE_STAGES.OPP_DEFENSE) {
            this.oppDefenseBar.stopMovement();
            this.oppDefense = this.oppDefenseBar.getCurrentPerc();
            await Utils.sleep(1000); // wait a sec
            await this.battle.attack('left');

            const damage = this.getDamage(this.myAttack, this.oppDefense);
            this.oppHealth -= damage;
            Global.game.sound.playSFX('battle-attack');
            console.log('damanged opp!');
            // TODO: add delay

            if (this.oppHealth <= 0){
                this.oppHealth = 0;
                this.onWin();
            } else {
                this.currPhase = BattleController.BATTLE_STAGES.OPP_ATTACK;
                this.startAttack('right');
                setTimeout(() => {
                    this.continueBattle();
                }, Math.random()*1000+1000);
            }

            this.battle.updateHealth('right', this.oppHealth);
        } else if (this.currPhase === BattleController.BATTLE_STAGES.OPP_ATTACK) {
            this.oppAttackBar.stopMovement();
            this.oppAttack = this.oppAttackBar.getCurrentPerc();
            this.currPhase = BattleController.BATTLE_STAGES.YOUR_DEFENSE;
            this.startDefense('left');
            this.battle.setSpaceBarVisibility(true);
            this.setupKeyListener(); // need to listen for space bar
        } else if (this.currPhase === BattleController.BATTLE_STAGES.YOUR_DEFENSE) {
            // stop our bar
            this.myDefenseBar.stopMovement();
            this.myDefense = this.myDefenseBar.getCurrentPerc();
            this.battle.setSpaceBarVisibility(false);
            await Utils.sleep(1000);
            await this.battle.attack('right');

            const damage = this.getDamage(this.oppAttack, this.myDefense) * 1.3; // 25% more to fix difficulty
            this.myHealth -= damage;
            console.log('damanged self!');
            Global.game.sound.playSFX('battle-attack');
            // TODO: add delay

            if (this.myHealth <= 0){
                this.myHealth = 0;
                this.onLose();
            } else {
                this.currPhase = BattleController.BATTLE_STAGES.OPP_ATTACK;
                this.battle.clearAttackDefBars();
                this.startBattle();
                this.keyProcessed = false;
            }
            this.battle.updateHealth('left', this.myHealth);
        }
    }

    /**
     * Calculates the damage based on attack and defense values.
     * @param {number} attack - The attack value (0-100).
     * @param {number} defense - The defense value (0-100).
     * @returns {number} - The calculated damage.
     */
    getDamage(attack, defense) {
        // return 1000;
        // Normalize attack value to a range of 10 to 100
        const normalizedAttack = 10 + (attack / 100) * 90;

        // Normalize defense to a discount of 10% to 50%
        const defenseDiscount = 0.1 + (defense / 100) * 0.4;

        // Calculate the damage after applying the defense discount
        const damage = normalizedAttack * (1 - defenseDiscount);
        
        return damage;
    }

    onWin() {
        this.battle.clearAttackDefBars();
        this.battle.showDialog('Congrats, you won!!', () => {
            this.onBattleComplete(true);
        });
    }

    onLose() {
        this.battle.clearAttackDefBars();
        this.battle.showDialog('You lost. Better luck next time.', () => {
            this.onBattleComplete(false);
        });
    }

    startBattle(){
        this.currPhase = BattleController.BATTLE_STAGES.YOUR_ATTACK;
        this.startAttack('left'); // start with your attack
        this.battle.setSpaceBarVisibility(true);
        this.setupKeyListener(); // need to listen for space bar
    }

    startAttack(side) {
        if (side === 'left') {
            this.myAttackBar = this.battle.showAttackBar(side);
        } else {
            this.oppAttackBar = this.battle.showAttackBar(side);
        }
    }
    startDefense(side) {
        if (side === 'left') {
            this.myDefenseBar = this.battle.showDefenseBar(side);
        } else {
            this.oppDefenseBar = this.battle.showDefenseBar(side);
        }
    }

    /**
     * Cleanup when the component is no longer needed.
     */
    destroy() {
        document.removeEventListener('keydown', this.onKeyDown);
        super.destroy();
        this.battle.destroy();
    }
}

export default BattleController;
