import React, {useEffect, useRef, useState} from "react";
import Global from "../Global";
import * as PIXI from "pixi.js";
import Game from "../game/Game";
import airdropBtn from "../images/airdrop-btn.png"
import coinImg from "../images/coin.png"
import "./Game.css";
import CreatureAPI from "../API";
import AirdropDialog from "../components/AirdropDialog";
import CreatureStorage from "../game/CreatureStorage";

const isMobileBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Regular expressions for mobile User Agent strings
    return /android/i.test(userAgent) ||
        /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

/**
 * Formats a number with commas as thousands separators.
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number with commas.
 */
const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const GamePage = () => {
    // Create a ref for the div where the PIXI app will be mounted
    const pixiContainer = useRef(null);
    const isMobile = isMobileBrowser();

    const [numReferrals, setNumReferrals] = useState(0);
    const [referralUrl, setReferralUrl] = useState('');
    const [numCoins, setNumCoins] = useState(0);
    const [isAirdropDialogOpen, setAirdropDialogOpen] = useState(false);

    const handleAirdropClick = () => {
        setAirdropDialogOpen(true);
    };

    const closeDialog = () => {
        setAirdropDialogOpen(false);
    };

    useEffect(() => {
        const url = window.location.href;
        if (!url.endsWith('/')) {
            const newUrl = url + '/';
            window.history.replaceState(null, '', newUrl);
        }
    }, []);

    useEffect(() => {
        const fetchReferralInfo = async () => {
            try {
                const referralInfo = await CreatureAPI.getReferralInfo();
                setNumReferrals(referralInfo.numReferrals);
                setReferralUrl(referralInfo.referralUrl);

                // Assuming the API also returns numCoins in the referralInfo
                // If not, you will need to fetch it separately
                setNumCoins(referralInfo.numCoins);
            } catch (error) {
                console.error('Error fetching referral info:', error);
                // Handle error, e.g., set state to show error message
            }
        };

        fetchReferralInfo();
    }, []);

    useEffect(() => {
        document.body.style.backgroundColor = 'black';
        if (isMobile) return () => {
            document.body.style.backgroundColor = null;
        };

        // Initialize the PIXI app
        Global.app = new PIXI.Application({width: Global.width, height: Global.height});

        // Append the PIXI view (canvas) to the ref element
        if (pixiContainer.current) {
            pixiContainer.current.appendChild(Global.app.view);
        }

        // Create the game instance and add it to the stage
        const game = new Game();
        Global.app.stage.addChild(game);
        Global.game = game;
        Global.game.showAirdropDialog = handleAirdropClick;

        // Cleanup function to remove the PIXI app when the component is unmounted
        return () => {
            Global.app.destroy(true, true);
            document.body.style.backgroundColor = null;
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!CreatureStorage.getShowedPopup()){
                handleAirdropClick();
                CreatureStorage.setShowedPopup(true);
            }
        }, 45 * 1000); // 45 seconds

        // Clean up the timer when the component is unmounted or when the useEffect is run again
        return () => clearTimeout(timer);
    }, []);

    // Prevent arrow keys from scrolling
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                event.preventDefault(); // Prevent the default action (scrolling)
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (Global.game){
        // update reference as needed
        Global.game.showAirdropDialog = handleAirdropClick;
    }

    if (isMobile) {
        return (
            <div className={'mobile-error'}>
                <div>
                    Please play the game on a desktop
                </div>
            </div>
        );
    }

    return (
        <div className="GamePage-Container">
            <div className={'pixi-container'} ref={pixiContainer}>
                <div className={'below-game'}>
                    <div className={'coin-row'}>
                        <div>
                            <img width={30} src={coinImg} />
                        </div>
                        <div className={'num-coins'}>
                            {formatNumberWithCommas(numCoins)}
                        </div>
                    </div>
                    <div className={'below-game-columns'} >
                        <div className={'below-game-col'}>
                            <div>
                                <img
                                    className={'airdrop-btn'}
                                    onClick={handleAirdropClick}
                                    src={airdropBtn}
                                    width={200}
                                />
                                <div className={'airdrop-txt'}>
                                    (limited time airdrop)
                                </div>
                            </div>

                        </div>
                        <div className={'below-game-col'}>
                            <div>
                                <b style={{fontSize: '25px'}}>Controls</b>
                                <br/>
                                <br/>
                                <div>
                                    Mouse - click
                                </div>
                                <div>
                                    Arrow keys - move
                                </div>
                                <div>
                                    Spacebar - menu
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'chat-input'}>
                    <input id={'chat-input'} className={'input-text'} type={'text'} placeholder={'Type here...'}/>
                </div>
            </div>
            <AirdropDialog
                isOpen={isAirdropDialogOpen}
                onClose={closeDialog}
                numReferrals={numReferrals}
                referralUrl={referralUrl}
            />
        </div>
    );
}

export default GamePage;