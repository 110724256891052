import * as PIXI from 'pixi.js';

class Rival extends PIXI.Sprite {
    constructor() {
        // Load the texture for the rival
        const rivalTexture = PIXI.Texture.from('assets/etc/rival.png');

        rivalTexture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;

        super(rivalTexture);

        // Additional setup, if required, goes here
        // For example, setting the position or scale of the sprite
        this.scale.set(2);
    }
}

export default Rival;
