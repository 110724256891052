import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './CreaturePage.css';
import logo from "../images/logo.png";
import freeMintBtn from "../images/free-mint-btn.png";

/**
 * Converts a referral code back to a creature ID.
 * @param {string} encodedId - The referral code to convert.
 * @returns {number} The original creature ID.
 */
export const getCreatureId = (encodedId) => {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let creatureId = 0;
    for (let i = 0; i < encodedId.length; i++) {
        creatureId = creatureId * ALPHABET.length + ALPHABET.indexOf(encodedId[i]);
    }
    return creatureId;
};

const CreaturePage = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const imageUrl = `https://creaturesgame.s3.us-west-1.amazonaws.com/creatures-${getCreatureId(id)}.png`;

    const gotoLogin = () => {
        navigate('/login');
    }

    return (
        <div className="creature-background">
            <div><img className="creature-page-logo" src={logo} alt="Logo" /></div>
            <div className="creature-container">
                <div className="creature-dialog">
                    <div className="creature-title">
                        <h2>Create your own!</h2>
                    </div>
                    <div>
                        <img
                            className={'creature-page-img'}
                            width={250}
                            src={imageUrl} />
                    </div>
                    <div className="creature-actions">
                        <div>Limited mints available</div>
                        <img
                            onClick={gotoLogin}
                            className={'free-mint-btn'}
                            src={freeMintBtn}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreaturePage;
