import * as PIXI from "pixi.js";
import Global from "../Global";

/**
 *
 * @param dir {String} down, up, left, right
 */
const getWalkingSprite = (dir) => {
    let walkingDown = [`assets/player/player_${dir}1.png`, `assets/player/player_${dir}2.png`, `assets/player/player_${dir}1.png`, `assets/player/player_${dir}3.png`];
    let walkingDownTextures = [];

    for (let i = 0; i < 4; i++) {
        let texture = PIXI.Texture.from(walkingDown[i]);
        texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
        walkingDownTextures.push(texture);
    }

    let animatedSprite = new PIXI.AnimatedSprite(walkingDownTextures);
    animatedSprite.animationSpeed = 0.1;

    return animatedSprite;
}

class CPUCharacter extends PIXI.Container {
    constructor(playerName) {
        super();

        this.playerName = playerName;
        this.spriteWidth = this.spriteHeight = 40;
        this.dir = "down";
        this.walkingSprites = {
            "down": getWalkingSprite("down"),
            "up": getWalkingSprite("up"),
            "left": getWalkingSprite("left"),
            "right": getWalkingSprite("right"),
        }
        this.prevSprite = null;
        this.isWalking = false;
        this.walkingSpeed = 4 * 1; // 4
        this.updateSprite();
        this.addUserName();

        setInterval(this.onWalk.bind(this), 25);
    }

    addUserName() {
        // Create a text object for the player's name
        const nameText = new PIXI.Text(this.playerName, {
            fontFamily: 'Arial',
            fontSize: 10,
            fill: 0xFFFFFF // White color
        });
        console.log(this.playerName);

        // Calculate the width and height of the text (consider some padding)
        const padding = 4;
        const textWidth = nameText.width + padding * 2;
        const textHeight = nameText.height + padding * 2;

        // Create a slightly transparent black background for the text
        const background = new PIXI.Graphics();
        background.beginFill(0x000000, 0.5); // 50% transparent black
        background.drawRect(-textWidth / 2, -textHeight / 2, textWidth, textHeight);
        background.endFill();

        // Create a container to hold both the text and its background
        const nameContainer = new PIXI.Container();
        nameContainer.addChild(background);
        nameContainer.addChild(nameText);

        // Center the text within the container
        nameText.x = -nameText.width / 2;
        nameText.y = -nameText.height / 2;

        // Set the position of the container
        nameContainer.x = this.width / 2; // Assuming 'this' is a PIXI.Container
        nameContainer.y = 50;

        // Add the container to the parent (current) object
        this.addChild(nameContainer);
    }

    setDir(dir) {
        this.dir = dir;
        this.isWalking = false;
        this.updateSprite();
    }

    onWalk() {
        // If there's no target, or character isn't walking, return early
        if (!this.isWalking || this.targetX === undefined || this.targetY === undefined) {
            return;
        }

        if (Math.abs(this.x-this.targetX) <= this.walkingSpeed) {
            this.x = this.targetX;
        }
        if (Math.abs(this.y-this.targetY) <= this.walkingSpeed) {
            this.y = this.targetY;
        }

        // snap to place if distance is greather 220 (normally around ~160 tops, so if higher likely stuck)
        if (Math.sqrt(Math.pow(this.x-this.targetX, 2) + Math.pow(this.y-this.targetY, 2)) >= 220) {
            this.x = this.targetX;
            this.y = this.targetY;
        }

        if (Math.abs(this.x-this.targetX) <= this.walkingSpeed && Math.abs(this.y-this.targetY) <= this.walkingSpeed) {
            this.finishMoving();
        }

        // Calculate the direction to the target if not already moving
        if (this.currentDir === undefined) {
            const deltaX = this.targetX - this.x;
            const deltaY = this.targetY - this.y;

            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                // Prefer horizontal movement
                this.currentDir = deltaX > 0 ? "right" : "left";
            } else if (Math.abs(deltaY) > 0) {
                // Prefer vertical movement
                this.currentDir = deltaY > 0 ? "down" : "up";
            } else {
                // Target reached, set final direction
                this.currentDir = this.targetDir;
                this.finishMoving();
                return;
            }
        }

        // Calculate the next position based on the current direction
        let newX = this.x + (this.currentDir === "right" ? this.walkingSpeed : this.currentDir === "left" ? -this.walkingSpeed : 0);
        let newY = this.y + (this.currentDir === "down" ? this.walkingSpeed : this.currentDir === "up" ? -this.walkingSpeed : 0);

        // Check collision or if reached the target for the current axis
        if (!Global.world.map.collidesWithHitBoxes(newX, newY, this.spriteWidth/1.5, this.spriteHeight) &&
            !this.reachedAxisTarget(newX, newY)) {
            this.x = newX;
            this.y = newY;
            this.updateSpriteIfDirChanged();
        } else {
            // Change direction or finish moving if target reached
            this.changeDirectionOrFinish();
        }
    }

    reachedAxisTarget(newX, newY) {
        return (this.currentDir === "right" && newX >= this.targetX) ||
            (this.currentDir === "left" && newX <= this.targetX) ||
            (this.currentDir === "down" && newY >= this.targetY) ||
            (this.currentDir === "up" && newY <= this.targetY);
    }

    changeDirectionOrFinish() {
        if (this.currentDir === "right" || this.currentDir === "left") {
            // Change to vertical movement if possible
            this.currentDir = this.targetY > this.y ? "down" : "up";
        } else {
            // Change to horizontal movement if possible
            this.currentDir = this.targetX > this.x ? "right" : "left";
        }

        // Check if the target has been reached
        if ((this.currentDir === "right" || this.currentDir === "left") && this.x === this.targetX ||
            (this.currentDir === "down" || this.currentDir === "up") && this.y === this.targetY) {
            this.finishMoving();
        }
    }

    updateSpriteIfDirChanged() {
        if (this.dir !== this.currentDir) {
            this.dir = this.currentDir;
            this.updateSprite();
        }
    }

    finishMoving() {
        this.x = this.targetX;
        this.y = this.targetY;
        this.dir = this.targetDir;
        this.isWalking = false;
        this.currentDir = undefined;
        this.updateSprite();
    }


    updateSprite() {
        if (this.prevSprite) this.removeChild(this.prevSprite);
        this.prevSprite = this.walkingSprites[this.dir];
        this.prevSprite.scale.set(2, 2);
        this.addChild(this.prevSprite);

        if (this.isWalking) {
            this.prevSprite.gotoAndPlay(1);
        }
        else {
            this.prevSprite.gotoAndStop(0);
        }
    }

    moveUp() {
        if (this.dir !== "up" || !this.isWalking) {
            this.dir = "up";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveDown() {
        if (this.dir !== "down" || !this.isWalking) {
            this.dir = "down";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveLeft() {
        if (this.dir !== "left" || !this.isWalking) {
            this.dir = "left";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    moveRight() {
        if (this.dir !== "right" || !this.isWalking) {
            this.dir = "right";
            this.isWalking = true;
            this.updateSprite();
        }
    }

    stopMoving() {
        this.isWalking = false;
        this.updateSprite();
    }

    /**
     * This method moves the character greedily while avoiding obstacles to the target x/y and dir
     * @param x
     * @param y
     * @param dir
     */
    setTarget(x, y, dir) {
        this.targetX = x;
        this.targetY = y;
        this.targetDir = dir;
        this.isWalking = true;
        this.updateSprite();
    }
}

export default CPUCharacter;