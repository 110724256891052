import * as PIXI from "pixi.js";
import World from "./World";
import Map from './maps/Map';
import Sound from '../Sound.js';
import UIManager from "./UI/UIManager";
import AssetLoader from './AssetLoader';
import API from '../API';
import Global from "../Global";
import BattleController from "./battle/BattleController";
import WebFont from 'webfontloader';
import CreatureStorage from "./CreatureStorage";
import LoadingText from "./UI/components/LoadingText";
import Socket from "../Socket";
import CPUCharacter from "./CPUCharacter";
import Utils from "../Utils";
import Telemetry from "../Telemetry";

class Game extends PIXI.Container {
    constructor() {
        super();
        Telemetry.init();
        this.battling = false;
        this.changingMaps = false;
        this.canMove = true;
        this.socketConnected = false;
        this.sound = new Sound();
        this.gotoMap = this.gotoMap.bind(this);

        // Flags to track loading status
        this.assetsLoaded = false;
        this.fontsLoaded = false;
        this.soundLoaded = false;

        this.loadingText = new LoadingText();
        this.addChild(this.loadingText);

        this.loadingText.x = Global.width / 2 - this.loadingText.width / 2;
        this.loadingText.y = Global.height / 2 - this.loadingText.height / 2;

        this.loadAssets();
        const token = CreatureStorage.getToken();
        if (!token) window.location.href = '/';

        // socket related stuff
        this.socket = new Socket(this.onSocketConnected.bind(this));
        this.socket.handleCurrentWorldStatus = this.onCurrentWorldStatus.bind(this);
        this.socket.handlePlayerLeaveMap = this.onPlayerLeave.bind(this);
        this.socket.handlePlayerJoinMap = this.onPlayerJoin.bind(this);
        this.socket.handleCharStateUpdate = this.onPlayerUpdate.bind(this);
        this.socket.handleBroadcastChat = this.onNewChat.bind(this);
        this.handleCharsZ = this.handleCharsZ.bind(this);
        this.currentState = null;
        this.newStateInterval = setInterval(this.announceNewState.bind(this), 1000);
        this.otherPlayers = [];

        PIXI.Ticker.shared.add(this.handleCharsZ);
    }

    onSocketConnected() {
        this.socketConnected = true;
        this.checkAllLoaded();
    }

    loadAssets() {
        AssetLoader.loadAll(() => {
            this.assetsLoaded = true;
            this.checkAllLoaded();
        });

        WebFont.load({
            google: {
                families: ['Roboto']
            },
            active: () => {
                this.fontsLoaded = true;
                this.checkAllLoaded();
            }
        });

        this.sound.onLoaded = () => {
            this.soundLoaded = true;
            this.checkAllLoaded();
        };
        this.sound.preloadBGMusic({
            'bg': 'assets/sound/bg.mp3',
            'battle': 'assets/sound/battle.mp3'
        }, 'bg');
        this.sound.preloadSFX({
            'dialog-continue': 'assets/sound/dialog-continue.mp3',
            'battle-attack': 'assets/sound/battle-attack.mp3',
        });
    }

    checkAllLoaded() {
        if (this.assetsLoaded && this.fontsLoaded && this.soundLoaded && this.socketConnected) {
            this.removeChild(this.loadingText);
            this.initGame();
        }
    }

    initGame() {
        // clean up ====================================
        this.removeChildren();
        // =============================================
        Global.game = this;
        this.world = new World();
        this.UI = new UIManager();
        this.addChild(this.world);
        this.addChild(this.UI);
        this.gotoMap(Map.MapCodes.LAB, false);
        // this.gotoMap(Map.MapCodes.HOME, false);

        Global.world = this.world;

        // Bind the event handlers
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);

        // event listeners
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    destroy(options) {
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
        this.sound.stopBGMusic();
        this.world.destroy();
        clearInterval(this.newStateInterval);
        if (this.battleController) this.battleController.destroy();
        if (this.socket) this.socket.destroy();
        PIXI.Ticker.shared.remove(this.handleCharsZ);
    }

    /**
     * Adjusts the z-index of each character based on their y position.
     * This method sorts all characters (including this.world.char and
     * all containers in this.otherPlayers) by their y position.
     * Characters with higher y values (lower on the screen) are given
     * a higher z-index, so they appear above those with lower y values.
     */
    handleCharsZ() {
        if (!this.otherPlayers) return;

        const allChars = [...this.otherPlayers];
        if (this.world && this.world.char) {
            allChars.push(this.world.char);
        }

        allChars.sort((a, b) => a.y - b.y);
        allChars.forEach((char, index) => {
            this.world.setChildIndex(char, index + 1);
        });

        this.handleCharCollisions();
    }

    /**
     * Checks for collisions between this.world.char and each character in this.otherPlayers.
     * If a collision is detected, the alpha of the colliding character is set to 0.5.
     * If there's no collision, their alpha is set to 1.
     */
    handleCharCollisions() {
        if (!this.world) return;

        const mainCharRect = {
            x: this.world.char.x,
            y: this.world.char.y,
            width: 40,
            height: 40
        };

        this.otherPlayers.forEach(player => {
            const playerRect = {
                x: player.x,
                y: player.y,
                width: 40,
                height: 40
            };

            if (Utils.rectsIntersect(mainCharRect, playerRect)) {
                player.alpha = 0.5; // Collision detected, set alpha to 0.5
            } else {
                player.alpha = 1; // No collision, set alpha to 1
            }
        });
    }

    async handleLabEntry() {
        const mintingElegibility = await API.getMintingEligibility();
        const {canMint, needsCoins} = mintingElegibility;

        const goHomeAndShowPopup = () => {
            this.gotoMap(Map.MapCodes.HOME, true);
            Global.game.showAirdropDialog();
        };

        if (!canMint) {
            if (needsCoins === 0) { // can't earn more mints with more coins
                this.UI.showDialog('You\'ve hit your mint limit.', true, this.gotoMap.bind(this, Map.MapCodes.HOME, true));
            } else {
                this.UI.showDialog(`You need ${needsCoins} coins to mint\nmore.`, true, goHomeAndShowPopup.bind(this));
            }
            return;
        }
        this.UI.show(UIManager.FLOWS.CREATE_CREATURE, this.onCreateCreature.bind(this));
    }

    /**
     * Asynchronously fades in the black overlay.
     * @param {number} duration - Duration of the fade in milliseconds.
     * @returns {Promise} A promise that resolves when fade-in is complete.
     */
    async fadeInBlack(duration) {
        return new Promise((resolve) => {
            // Create and add the black overlay
            this.blackOverlay = new PIXI.Graphics();
            this.blackOverlay.beginFill(0x000000);
            this.blackOverlay.drawRect(0, 0, window.innerWidth, window.innerHeight); // Adjust size as needed
            this.blackOverlay.endFill();
            this.blackOverlay.alpha = 0;
            this.addChild(this.blackOverlay);

            let elapsed = 0;
            const update = (delta) => {
                elapsed += delta;
                if (this.blackOverlay) {
                    this.blackOverlay.alpha = Math.min(elapsed / duration, 1);
                }
                if (elapsed >= duration || !this.blackOverlay) {
                    PIXI.Ticker.shared.remove(update);

                    resolve();
                }
            };
            PIXI.Ticker.shared.add(update);
        });
    }

    /**
     * Asynchronously fades out the black overlay.
     * @param {number} duration - Duration of the fade in milliseconds.
     * @returns {Promise} A promise that resolves when fade-out is complete.
     */
    async fadeOutBlack(duration) {
        return new Promise((resolve) => {
            let elapsed = 0;
            const update = (delta) => {
                elapsed += delta;
                if (this.blackOverlay)
                    this.blackOverlay.alpha = Math.max(1 - elapsed / duration, 0);
                if (elapsed >= duration) {
                    PIXI.Ticker.shared.remove(update);
                    this.removeChild(this.blackOverlay);
                    this.blackOverlay = null;
                    resolve();
                }
            };
            PIXI.Ticker.shared.add(update);
        });
    }

    async gotoMap(mapCode, fadeIn = true) {
        if (this.changingMaps) return;
        this.changingMaps = true;
        await this.fadeInBlack(fadeIn ? 10 : 0);
        this.world.map.changeMap(mapCode);

        if (mapCode !== Map.MapCodes.HOME) {
            this.socket.leaveMap(Map.MapCodes.HOME);
        }
        this.currentMap = mapCode;
        Telemetry.trackEvent('Enter map', {mapCode});
        switch (mapCode) {
            case Map.MapCodes.LAB:
                this.canMove = false;
                this.world.char.setDir('up');
                this.world.char.x = 488;
                this.world.char.y = 770;
                this.world.x = -90;
                this.world.y = -550;
                this.handleLabEntry();
                break;
            case Map.MapCodes.HOME:
                this.canMove = true;
                this.world.char.x = 1280;
                this.world.char.y = 780;
                // this.world.char.x = 880;
                // this.world.char.y = 380;
                this.world.char.setDir('down');
                this.world.x = -900;
                this.world.y = -450;
                this.UI.hideCurrentElement();
                this.socket.joinMap(Map.MapCodes.HOME, this.world.char.x, this.world.char.y, 'down');
                this.currentState = {
                    x: this.world.char.x,
                    y: this.world.char.y,
                    dir: this.world.char.dir,
                    counter: 0
                };
                break;
            default:
                break;
        }
        if (!this.sound.isPlaying) this.sound.playBGMusic('bg');
        await this.fadeOutBlack(30);
        this.changingMaps = false;
    }

    async onCreateCreature(element, species, size) {
        const dialogTexts = [
            "Preparing NFT for minting...",
            "Analyzing request...",
            "Architecting genome...",
            "Integrating elemental attributes...",
            "Orchestrating genome structure...",
            "Splicing proteins...",
            "Inserting base pairs...",
            "Refining epigenetic changes...",
            "Stabilizing genetic code...",
            "Validating integrity..."
        ];
        this.UI.show(UIManager.FLOWS.CREATING_CREATURE, null, dialogTexts);
        Telemetry.trackEvent('Start creating creature');
        try {
            const response = await API.createCreature({element, species, size});
            CreatureStorage.setCreatedCreature(true);
            this.UI.hideCurrentElement();
            Telemetry.trackEvent('Create creature');
            await this.showViewCreatures(true);
        } catch (err) {
            // show retry dialog and retry until it works lol
            this.UI.hideCurrentElement();
            if (err.message === 'No more of this selection left.') {
                this.UI.showDialog('Looks like your selection minted\nout... Pick something else :(', true, this.handleLabEntry.bind(this));
                Telemetry.trackEvent('Error creating creature', {reason: 'No more left'});
            } else {
                this.UI.showDialog('Something went wrong.\nTry again.', true, this.onCreateCreature.bind(this, element, species, size));
                Telemetry.trackEvent('Error creating creature', {reason: err.message});
            }

        }
    }

    async showViewCreatures(gotoHomeAfter = false) {
        Telemetry.trackEvent('View creatures');
        try {
            let currCreatures = await API.getUserCreatures();
            console.log(currCreatures);

            const onClose = () => {
                this.canMove = true;

                if (gotoHomeAfter) this.gotoMap(Map.MapCodes.HOME);
            }

            this.canMove = false;
            this.UI.show(UIManager.FLOWS.VIEW_CREATURE, onClose.bind(this), currCreatures);
        } catch (err) {
            if (gotoHomeAfter) {
                this.UI.showDialog('Something went wrong.\nTry again.', true, this.showViewCreatures.bind(this, true));
            } else {
                this.UI.showDialog('Something went wrong.\nTry again later.', true);
            }
        }

    }

    fightWithRival() {
        this.canMove = false;
        this.world.char.stopMoving();

        const onCancel = () => {
            this.canMove = true;
            Telemetry.trackEvent('Decline fight with rival');
        };

        const onNext = async () => {
            try {
                const currCreatures = API.getUserCreatures();
                this.sound.playBGMusic('battle');
                this.UI.setChatBarVisibility(false);
                this.battling = true;
                Telemetry.trackEvent('Start fight with rival');

                const fade = this.fadeInBlack(50);
                const result = await Promise.all([
                    currCreatures,
                    fade
                ]);
                const firstCreatureUrl = result[0][0].url;
                this.battleController = new BattleController(
                    firstCreatureUrl,
                    'https://creaturesgame.s3.us-west-1.amazonaws.com/creatures-2.png',
                );
                this.battleController.onBattleComplete = this.onFinishFightRival.bind(this);
                this.addChild(this.battleController);
                this.setChildIndex(this.blackOverlay, this.children.length - 1);
                await this.fadeOutBlack(50);
            } catch (err) {
                this.battling = false;
                this.sound.playBGMusic('bg');
                await this.fadeOutBlack(20);
                this.UI.showDialog('Something went wrong... Try again.', true, onCancel);
            }
        };

        if (!CreatureStorage.getFoughtRival()) {
            this.UI.showDialog('I can\'t let you pass kid.\nShow me what you got!', true, onNext);
        } else {
            this.UI.showDialogWithOption('Look. I can\'t let you pass.\nDo you want to battle again?', onNext, onCancel);
        }
    }

    async onFinishFightRival(won) {
        CreatureStorage.setFoughtRival(true);
        await this.fadeInBlack(50);
        this.removeChild(this.battleController);
        await this.fadeOutBlack(50);
        this.battleController = null;
        this.battling = false;
        this.sound.playBGMusic('bg');
        Telemetry.trackEvent('Finished fight with rival', {won});

        const onNext = () => {
            this.canMove = true;
        };
        if (won) {
            this.UI.showDialog('You might have won.\nBut you\'re still not ready...', true, onNext);
        } else {
            this.UI.showDialog('See? You\'re not ready for the\nreal world!', true, onNext);
        }
    }

    onKeyDown(e) {
        if (this.battling) return;
        const focusedElement = document.activeElement;
        let isFocusedOnTextInput = false;

        if (focusedElement && document.activeElement.tagName.toLowerCase() === 'input' && document.activeElement.type === 'text') {
            isFocusedOnTextInput = true;
        }

        if (e.key === 'Enter' && isFocusedOnTextInput) {
            this.sendChat();
        }

        if (e.key === 'Escape' && isFocusedOnTextInput) {
            focusedElement.blur();
        }
        if (isFocusedOnTextInput) return;

        if (this.canMove) {
            if (e.key === 'ArrowLeft') {
                this.world.char.moveLeft();
            } else if (e.key === 'ArrowRight') {
                this.world.char.moveRight();
            } else if (e.key === 'ArrowUp') {
                this.world.char.moveUp();
            } else if (e.key === 'ArrowDown') {
                this.world.char.moveDown();
            }
        }

        if (e.key === ' ') {
            if (!this.UI.isShowingUI()) {
                this.UI.show(UIManager.FLOWS.MAIN_MENU, () => {
                    this.canMove = true;
                });
                this.canMove = false;
                this.world.char.stopMoving();
            } else if (this.UI.currentFlow === UIManager.FLOWS.MAIN_MENU) {
                this.UI.hideCurrentElement();
                this.canMove = true;
            }
        }
    }

    onKeyUp(e) {
        if (e.key === 'ArrowLeft') {
            this.world.char.stopMoving();
        } else if (e.key === 'ArrowRight') {
            this.world.char.stopMoving();
        } else if (e.key === 'ArrowUp') {
            this.world.char.stopMoving();
        } else if (e.key === 'ArrowDown') {
            this.world.char.stopMoving();
        }
    }

    sendChat() {
        let chat = document.getElementById('chat-input').value;

        chat = chat.substring(0, 160); // max

        if (chat.length === 0) return;
        Global.game.socket.sendChatMessage(chat);
        document.getElementById('chat-input').value = '';
        Telemetry.trackEvent('Sent chat', {chat});
    }

    /**
     * Announce new state to other players if needed
     */
    announceNewState() {
        if (this.currentMap !== Map.MapCodes.HOME || !this.world.char) return;

        if (
            this.world.char.x !== this.currentState.x ||
            this.world.char.y !== this.currentState.y ||
            this.world.char.dir !== this.currentState.dir
        ) {
            let counter = this.currentState.counter;

            this.currentState = {
                x: this.world.char.x,
                y: this.world.char.y,
                dir: this.world.char.dir,
                counter: counter + 1
            };
            this.socket.updateCharState(
                this.currentState.x,
                this.currentState.y,
                this.currentState.dir,
                this.currentState.counter
            );
        }
    }

    /**
     * Called whenever we receive state fill from a blank slate
     * @param data {Array} [
     *  {counter, dir, playerId, x, y},
     *  ...
     * ]
     */
    onCurrentWorldStatus(data) {
        // First, remove all existing CPUCharacter instances from the PIXI scene
        this.otherPlayers.forEach(player => {
            this.world.removeChild(player);
        });

        // Clear the otherPlayers array
        this.otherPlayers = [];

        // Iterate over the data array to create and add new CPUCharacters
        data.players.forEach(playerData => {
            let cpuCharacter = new CPUCharacter(playerData.playerName);

            // Set the properties of the cpuCharacter based on the playerData
            cpuCharacter.x = playerData.x;
            cpuCharacter.y = playerData.y;
            cpuCharacter.dir = playerData.dir;
            cpuCharacter.playerId = playerData.playerId;
            cpuCharacter.updateSprite();

            // Add the cpuCharacter to the PIXI scene
            this.world.addChild(cpuCharacter);

            // Store the cpuCharacter in the otherPlayers array for future reference
            this.otherPlayers.push(cpuCharacter);
        });
    }

    socketReconnected() {
        this.socket.joinMap(Map.MapCodes.HOME, this.world.char.x, this.world.char.y, this.world.char.dir);
        Telemetry.trackEvent('Reconnected socket');
    }

    /**
     * Called when a player leaves the map
     * @param data {Object}
     */
    onPlayerLeave(data) {
        console.log(data);
        console.log(`${data.playerId} left map ${data.mapId}`);

        // Find the CPUCharacter instance that corresponds to the leaving player
        const leavingPlayerIndex = this.otherPlayers.findIndex(player => player.playerId === data.playerId);

        // If the player is found, remove them from the PIXI scene and the otherPlayers array
        if (leavingPlayerIndex !== -1) {
            const leavingPlayer = this.otherPlayers[leavingPlayerIndex];

            // Remove the player from the PIXI scene
            this.world.removeChild(leavingPlayer);

            // Remove the player from the otherPlayers array
            this.otherPlayers.splice(leavingPlayerIndex, 1);
        }
    }

    /**
     * Called when a new player joins
     * @param data
     */
    onPlayerJoin(data) {
        let newPlayer = new CPUCharacter(data.playerName);

        // Set the properties of the newPlayer based on the received data
        newPlayer.x = data.x;
        newPlayer.y = data.y;
        newPlayer.dir = data.dir;
        newPlayer.playerId = data.playerId;
        newPlayer.updateSprite();  // Assuming there's a method to update the character's appearance

        // Add the newPlayer to the PIXI scene
        this.world.addChild(newPlayer);

        // Add the newPlayer to the array of other players for future reference
        this.otherPlayers.push(newPlayer);
    }

    onPlayerUpdate(data) {
        const player = this.otherPlayers.find(p => p.playerId === data.playerId);

        if (player) {
            // Update the player's state only if the new counter is higher
            if (!player.counter || data.counter > player.counter) {
                player.counter = data.counter;
                player.setTarget(data.x, data.y, data.dir);
                // player.updateSprite();
            }
        } else {
            console.warn(`Player with ID ${data.playerId} not found`);
        }
    }

    onNewChat(data) {
        const text = `${data.playerName}: ${data.message}`
        this.UI.chatMessages.addChat(text);
    }
}

export default Game;