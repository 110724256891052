import * as PIXI from 'pixi.js';
import Global from '../../../Global';
import UIManager from "../UIManager";

/**
 * The MainMenu class extends PIXI.Container to create a main menu element.
 */
class MainMenu extends PIXI.Container {
    /**
     * Constructs a new MainMenu instance.
     */
    constructor() {
        super();
        this.menuSprite = null;

        this.loadMenu();
        this.addTitle();
        this.addMenuOption();
        this.addExitOption();
    }

    /**
     * Loads the menu image, rotates it, and adds it as a sprite.
     */
    loadMenu() {
        const menuTexture = PIXI.Texture.from('assets/ui/dialog-bg.png');

        this.menuSprite = new PIXI.Sprite(menuTexture);
        this.menuSprite.rotation = Math.PI / 2; // Rotate 90 degrees
        this.menuSprite.x = Global.width - 20;
        this.menuSprite.scale.x = this.menuSprite.scale.y = 0.5;

        this.addChild(this.menuSprite);
    }

    /**
     * Adds the menu title to the top of the menu.
     */
    addTitle() {
        const title = new PIXI.Text('Menu', {
            fontFamily: 'Press Start 2P',
            fontSize: 20,
            fill: 0xFFFFFF,
            align: 'center'
        });

        // Position the title at the top-center of the rotated menu
        title.anchor.set(0.5, 0);
        title.x = Global.width - 100;
        title.y = 20;

        this.addChild(title);
    }

    /**
     * Adds the "View creature" menu option as an interactive button.
     */
    addMenuOption() {
        // Create the text for the button
        const option = new PIXI.Text('My Creatures', {
            fontFamily: 'Press Start 2P',
            fontSize: 11,
            fill: 0xFFFFFF,
            align: 'center'
        });

        // Enable interaction on the text
        option.interactive = true;
        option.buttonMode = true;

        // Set the anchor point to the center
        option.anchor.set(0.5, 0);

        // Position the button below the title
        option.x = Global.width - 105;
        option.y = 70; // Adjust as needed for correct spacing

        // Add an event listener for the pointerdown event
        option.on('pointerdown', this.onViewCreature.bind(this));

        this.addChild(option);
    }

    /**
     * Adds the "Exit" menu option as an interactive button.
     */
    addExitOption() {
        // Create the text for the button
        const option = new PIXI.Text('Back', {
            fontFamily: 'Press Start 2P',
            fontSize: 14,
            fill: 0xFFFFFF,
            align: 'center'
        });

        // Enable interaction on the text
        option.interactive = true;
        option.buttonMode = true;

        // Set the anchor point to the center
        option.anchor.set(0.5, 0);

        // Position the button below the title
        option.x = Global.width - 100;
        option.y = 120; // Adjust as needed for correct spacing

        // Add an event listener for the pointerdown event
        option.on('pointerdown', this.onExit.bind(this));

        this.addChild(option);
    }

    /**
     * The event handler for when the "View Creature" button is clicked.
     */
    onViewCreature() {
        Global.game.sound.playSFX('dialog-continue');
        this.onComplete();
        Global.game.showViewCreatures();
    }

    /**
     * The event handler for when the "Exit" button is clicked.
     */
    onExit() {
        Global.game.sound.playSFX('dialog-continue');
        this.onComplete();
    }
}

export default MainMenu;
