import * as PIXI from 'pixi.js';
import HealthBar from './components/HealthBar'; // Ensure this is the correct path to your HealthBar class
import CreatureFrame from './components/CreatureFrame'; // Ensure this is the correct path to your CreatureFrame class
import Global from '../../Global';
import BattleDialog from "./components/BattleDialog";
import BattleBar from "./components/BattleBar";
import Dialog from "../UI/components/Dialog";
import Spacebar from "./components/Spacebar";
import Utils from "../../Utils";

class Battle extends PIXI.Container {
    constructor(myHealth, oppHealth, leftUrl, rightUrl) {
        super();

        this.creatureFrameLeft = null;
        this.creatureFrameRight = null;
        this.healthBarLeft = null;
        this.healthBarRight = null;

        this.setBackground('assets/battle/battle-bg2.png');
        this.setupLogo();
        this.setupVersusIcon();
        this.setupHealthBars(myHealth, oppHealth);
        this.setupCreatureFrames(leftUrl, rightUrl);
    }

    setBackground(imageUrl) {
        const backgroundTexture = PIXI.Texture.from(imageUrl);
        const backgroundSprite = new PIXI.Sprite(backgroundTexture);

        // Calculate the scale factor to fit the height to Global.height
        const scaleFactor = Global.height / backgroundTexture.height;

        // Apply the scale factor to both width and height to maintain aspect ratio
        backgroundSprite.scale.x = scaleFactor;
        backgroundSprite.scale.y = scaleFactor;

        // Make sure the background is added first so it's behind all other objects
        this.addChildAt(backgroundSprite, 0);
    }
    setupLogo() {
        const logoTexture = PIXI.Texture.from('assets/battle/logo.png');
        const logoSprite = new PIXI.Sprite(logoTexture);

        // Set the scale of the logo
        logoSprite.scale.x = logoSprite.scale.y = 0.2;

        // Calculate the centered x and y position after scaling
        logoSprite.x = (Global.width - logoSprite.width) / 2;
        logoSprite.y = 12;

        this.addChild(logoSprite);
    }

    setupVersusIcon() {
        const versusTexture = PIXI.Texture.from('assets/battle/versus.png');
        const versusSprite = new PIXI.Sprite(versusTexture);

        // Set the scale of the versus icon
        versusSprite.scale.x = versusSprite.scale.y = 0.1;

        // Calculate the centered x and y position taking scale into account
        versusSprite.x = (Global.width - (versusTexture.width * versusSprite.scale.x)) / 2;
        versusSprite.y = (Global.height - (versusTexture.height * versusSprite.scale.y)) / 2 - 100;

        this.addChild(versusSprite);
    }

    setupHealthBars(myHealth, oppHealth) {
        const healthBarLeft = new HealthBar(myHealth);
        const healthBarRight = new HealthBar(oppHealth);

        healthBarLeft.x = 20; // Left health bar position
        healthBarRight.x = Global.width - healthBarRight.width - 20; // Right health bar position
        healthBarLeft.y = healthBarRight.y = 350; // Vertical position for both health bars

        this.addChild(healthBarLeft);
        this.addChild(healthBarRight);

        // Store the health bars in the instance for reference in setupCreatureFrames
        this.healthBarLeft = healthBarLeft;
        this.healthBarRight = healthBarRight;
    }

    setupCreatureFrames(leftUrl, rightUrl) {
        this.creatureFrameLeft = new CreatureFrame(leftUrl, 'right');
        this.creatureFrameRight = new CreatureFrame(rightUrl, 'left');

        // Horizontal positions to align with the health bars
        this.creatureFrameLeft.x = 20;
        this.creatureFrameRight.x = Global.width - 235 - 30;

        this.addChild(this.creatureFrameLeft);
        this.addChild(this.creatureFrameRight);

        const creatureFrameHeight = 250;
        this.creatureFrameLeft.y = this.healthBarLeft.y - creatureFrameHeight;
        this.creatureFrameRight.y = this.healthBarRight.y - creatureFrameHeight;
    }

    setupBattleDialog(onFinish){
        const close = () => {
            onFinish();
            this.removeChild(this.battleDialog);
        }
        this.battleDialog = new BattleDialog(close);
        this.addChild(this.battleDialog);
    }

    /**
     *
     * @param side {String} left or right
     */
    showAttackBar(side) {
        this[side+'attackBar'] = new BattleBar('attack');
        this.addChild(this[side+'attackBar']);

        if (side === 'left') {
            this[side+'attackBar'].x = 40;
            this[side+'attackBar'].y = 450;
        }
        if (side === 'right') {
            this[side+'attackBar'].x = 385;
            this[side+'attackBar'].y = 550;
        }

        return this[side+'attackBar'];
    }

    /**
     *
     * @param side {String} left or right
     */
    showDefenseBar(side) {
        this[side+'defenseBar'] = new BattleBar('defense');
        this.addChild(this[side+'defenseBar']);

        if (side === 'left') {
            this[side+'defenseBar'].x = 40;
            this[side+'defenseBar'].y = 550;
        }
        if (side === 'right') {
            this[side+'defenseBar'].x = 385;
            this[side+'defenseBar'].y = 450;
        }

        return this[side+'defenseBar'];
    }

    /**
     *
     * @param side {String} left or right
     */
    async attack(side) {
        const frame = (side === 'left') ? this.creatureFrameLeft : this.creatureFrameRight;
        const otherFrame = (side === 'left') ? this.creatureFrameRight : this.creatureFrameLeft;
        frame.attack();
        await Utils.sleep(260);
        otherFrame.blink();
    }

    updateHealth(side, health) {
        const bar = (side === 'left') ? this.healthBarLeft : this.healthBarRight;
        bar.setHealth(health);
    }

    clearAttackDefBars(){
        const bars = [
            'leftdefenseBar',
            'rightdefenseBar',
            'leftattackBar',
            'rightattackBar',
        ];

        for (let i = 0;i<bars.length;i++){
            if (this[bars[i]]) {
                this.removeChild(this[bars[i]]);
                this[bars[i]].destroy();
                this[bars[i]] = null;
            }
        }
    }

    showDialog(text, onClose) {
        this.dialog = new Dialog(text);
        this.dialog.onNext = () => {
          this.removeChild(this.dialog);
          onClose();
        };

        this.addChild(this.dialog);
    }

    /**
     * Sets the visibility of the spacebar sprite.
     * @param {boolean} visible - Whether the spacebar should be visible or not.
     */
    setSpaceBarVisibility(visible) {
        if (visible) {
            if (!this.spacebar) {
                // Create the spacebar sprite if it doesn't exist
                this.spacebar = new Spacebar();

                // Position the spacebar sprite as needed
                this.spacebar.x = 100;
                this.spacebar.y = 500;
                this.spacebar.scale.x = this.spacebar.scale.y = 0.35;

                // Add the spacebar sprite to the container
                this.addChild(this.spacebar);
            }
        } else {
            if (this.spacebar) {
                // Remove the spacebar sprite from the container
                this.removeChild(this.spacebar);
                this.spacebar = null; // Optional: Clear the reference
            }
        }
    }

    destroy() {
        this.clearAttackDefBars();
    }
}

export default Battle;
