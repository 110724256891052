const getBaseURL = () => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:4000';
    } else {
        return 'https://api.creaturesai.online';
    }
}

class CreatureAPI {
    static BASE_URL = getBaseURL();

    /**
     * Post a new creature to the server.
     * @param {Object} creatureData - The data for the new creature.
     * @param {string} creatureData.size - The size of the creature.
     * @param {string} creatureData.species - The species of the creature.
     * @param {string} creatureData.element - The element of the creature.
     * @returns {Promise<Object>} The response from the server.
     */
    static async createCreature({ size, species, element }) {
        const endpoint = `${CreatureAPI.BASE_URL}/creatures`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ size, species, element }),
            credentials: 'include' // To include cookies for the session if needed
        };

        try {
            const response = await fetch(endpoint, requestOptions);
            if (!response.ok) {
                // If the server response is not ok, throw an error with the status
                const error = await response.json();
                throw new Error(error.message || 'Error posting new creature');
            }
            // Parse the JSON response from the server
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error in createCreature:', error);
            throw error;
        }
    }

    /**
     * Get creatures for a specific user.
     * @param {string} userId - The ID of the user whose creatures to fetch.
     * @returns {Promise<Array>} The list of creatures for the user.
     */
    static async getUserCreatures() {
        const endpoint = `${CreatureAPI.BASE_URL}/users/creatures`;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' // To include cookies for the session if needed
        };

        try {
            const response = await fetch(endpoint, requestOptions);
            if (!response.ok) {
                // If the server response is not ok, throw an error with the status
                const error = await response.json();
                throw new Error(error.message || `Error fetching creatures for user`);
            }
            // Parse the JSON response from the server
            const creatures = await response.json();
            return creatures;
        } catch (error) {
            console.error('Error in getUserCreatures:', error);
            throw error;
        }
    }

    /**
     * Initiate the sign-up/login process by submitting an email.
     * @param {string} email - The user's email address.
     * @returns {Promise<Object>} The response from the server.
     */
    static async initiateLogin(email) {
        const endpoint = `${CreatureAPI.BASE_URL}/login`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ email })
        };

        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Error initiating login');
        }
        const loginResponse = await response.json();
        return loginResponse;
    }

    /**
     * Verify the user's email and code for account creation or login.
     * @param {string} email - The user's email address.
     * @param {string} verificationCode - The verification code sent to the email.
     * @param {string} [referralCode] - Optional referral code.
     * @returns {Promise<Object>} The response from the server.
     */
    static async verifyLogin(email, verificationCode, referralCode) {
        const endpoint = `${CreatureAPI.BASE_URL}/login/verify`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ email, verificationCode, referralCode })
        };

        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Error verifying login');
        }
        const verifyResponse = await response.json();
        return verifyResponse;
    }

    /**
     * Fetches referral information for the user.
     * @returns {Promise<Object>} The referral information from the server.
     */
    static async getReferralInfo() {
        const endpoint = `${CreatureAPI.BASE_URL}/referral-info`;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' // To include cookies for the session if needed
        };

        try {
            const response = await fetch(endpoint, requestOptions);
            if (!response.ok) {
                // If the server response is not ok, throw an error with the status
                const error = await response.json();
                throw new Error(error.message || 'Error fetching referral information');
            }
            // Parse the JSON response from the server
            const referralInfo = await response.json();
            return referralInfo;
        } catch (error) {
            console.error('Error in getReferralInfo:', error);
            throw error;
        }
    }

    /**
     * Fetches the count of each creature variation group.
     * @returns {Promise<Object>} The counts of creature variations from the server.
     */
    static async getCreatureVariationCounts() {
        const endpoint = `${CreatureAPI.BASE_URL}/creature-variations`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        };

        const response = await fetch(endpoint, requestOptions);
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Error fetching creature variation counts');
        }
        const countsResponse = await response.json();
        return countsResponse;
    }

    /**
     * Fetches the user's eligibility to mint a new creature.
     * @param {string} userUuid - The UUID of the user to check minting eligibility for.
     * @returns {Promise<Object>} An object with canMint status and needed coins.
     */
    static async getMintingEligibility() {
        const endpoint = `${CreatureAPI.BASE_URL}/can-mint-creature`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        };

        try {
            const response = await fetch(endpoint, requestOptions);
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Error fetching minting eligibility');
            }

            const eligibilityResponse = await response.json();
            return eligibilityResponse;
        } catch (error) {
            console.error('Error in getMintingEligibility:', error);
            throw error;
        }
    }
}

export default CreatureAPI;
