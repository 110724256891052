import React from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import './Home.css';
import logo from '../images/logo.png';
import iconBattle from '../images/icon-battle.png';
import iconExplore from '../images/icon-explore.png';
import iconGenerate from '../images/icon-generate-creature.png';
import playBtn from '../images/play-btn.png';
import claimCoinsBtn from '../images/claim-coins-btn.png';
import ImageGrid from "../components/ImageGrid";
import beam from '../images/beam.svg';
import twitterIcon from '../images/twitter-icon.svg';
import telegramIcon from '../images/telegram-icon.png';
import meritCircle from '../images/Merit Circle Investment.svg';
import VideoComponent from "../components/VideoComponent";
import CreatureStorage from "../game/CreatureStorage";

const HomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getReferralParam = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('r');
    };

    const referral = getReferralParam();

    const onPlay = () => {
        if (CreatureStorage.getToken()) {
            return window.location.href = '/game/';
        }
        navigate(referral ? `/login?r=${referral}`: '/login');
    }

    return (
        <div className={'home-page-container'}>
            <div className={'socials-container'}>
                <div className={'pointer'}>
                    <a href="https://twitter.com/creaturesmmorpg" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} width={60} height={60} />
                    </a>
                </div>
                {/*<div className={'pointer'}>*/}
                {/*    <a href="https://t.me/+dvZw8t3pi4c1OGYx" target="_blank" rel="noopener noreferrer">*/}
                {/*        <img src={telegramIcon} width={40} height={40} />*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            <div className="above-the-fold-container">
                <div className="cta-section">
                    <img className="logo" src={logo} alt="Logo" />
                    <div className="subtitle">Generative AI MMORPG</div>
                    <img
                        className="cta-btn"
                        src={referral ? claimCoinsBtn: playBtn}
                        width={300}
                        alt="Play"
                        onClick={onPlay}
                    />
                </div>
            </div>
            <div className="below-the-fold-container">
                <div className="how-it-works-text">How to Play</div>
                <div className="explanation-container">
                    <div className="explanation-section">
                        <img className="explanation-img" src={iconGenerate} alt="Dream up your creature with AI" />
                        <div className="explanation-text">
                            Dream up your creature with AI
                        </div>
                    </div>
                    <div className="explanation-section">
                        <img className="explanation-img" src={iconExplore} alt="Explore the world" />
                        <div className="explanation-text">
                             Explore the world
                        </div>
                    </div>
                    <div className="explanation-section">
                        <img className="explanation-img" src={iconBattle} alt="Battle other players (coming soon)" />
                        <div className="explanation-text">
                            Battle other players
                            <br/>
                            (coming soon)
                        </div>
                    </div>
                </div>
            </div>
            <div className={'powered-by-container'}>
                <div className={'powered-by'}>
                    Powered by <img className={'beam-svg'} src={beam} />
                </div>
            </div>
            <div className={'offset-container'} >
                <div className={'trailer-container'}>
                    <div className={'video-container'}>
                        <VideoComponent />
                    </div>
                </div>
                {/*<div className={'investors-container'}>*/}
                {/*    <div className={'section-title'}>Investors</div>*/}
                {/*    <div className={'investors-icon-container'}>*/}
                {/*        <a href={'https://twitter.com/MeritCircle_IO'} target={'_blank'}>*/}
                {/*            <img className={'investor-icon'} src={meritCircle} />*/}
                {/*        </a>*/}
                {/*        <div className={'merit-desc'}>The largest gaming DAO valued at <span>>$1B</span></div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div>
                    <div className={'grid-title'}>Community Creations</div>
                    <ImageGrid />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
