import * as PIXI from 'pixi.js';
import Dialog from "../../UI/components/Dialog";
import DialogChain from "../../UI/components/DialogChain";

class BattleDialog extends PIXI.Container {
    constructor(onFinish) {
        super();
        this.dc = new DialogChain([
            'In battle, you have to time when\nyou press the space bar.',
            'Try to get the attack and defense\nbars as close to full as possible.'
        ]);

        this.dc.onFinish = onFinish;

        this.addChild(this.dc);
    }
}

export default BattleDialog;
