const Utils = {
    /**
     * Pauses execution for a specified number of milliseconds.
     * @param {number} ms - The duration to sleep in milliseconds.
     * @returns {Promise} A promise that resolves after the specified duration.
     */
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * Helper method to check if two rectangles intersect.
     * @param {Object} rect1 - The first rectangle.
     * @param {Object} rect2 - The second rectangle.
     * @returns {boolean} True if the rectangles intersect, false otherwise.
     */
    rectsIntersect(rect1, rect2) {
        return (
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
        );
    }
};


export default Utils;
