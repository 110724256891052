import * as PIXI from 'pixi.js';
import CreateCreatureFlow from "./flows/CreateCreatureFlow";
import Dialog from "./components/Dialog";
import ViewCreatureDialog from "./components/ViewCreatureDialog";
import MainMenu from "./components/MainMenu";
import CreatingCreatureFlow from "./flows/CreatingCreatureFlow";
import ChatBar from "./components/ChatBar";
import Global from "../../Global";
import ChatMessages from "./components/ChatMessages";

class UIManager extends PIXI.Container {
    static FLOWS = {
        CREATE_CREATURE: 0,
        VIEW_CREATURE: 1,
        MAIN_MENU: 2,
        CREATING_CREATURE: 3,
    }
    static FLOW_COMPONENTS = {
        [UIManager.FLOWS.CREATE_CREATURE]: CreateCreatureFlow,
        [UIManager.FLOWS.VIEW_CREATURE]: ViewCreatureDialog,
        [UIManager.FLOWS.MAIN_MENU]: MainMenu,
        [UIManager.FLOWS.CREATING_CREATURE]: CreatingCreatureFlow,
    }
    constructor() {
        super();

        this.currentElement = null;
        this.chatBar = new ChatBar();
        this.addChild(this.chatBar);
        this.chatMessages = new ChatMessages();
        this.addChild(this.chatMessages);
        this.currentFlow = null;

        this.chatMessages.onHeightUpdated = this.updateMessagesPos.bind(this);

        this.chatBar.y = Global.height - this.chatBar.height + 2;
    }

    updateMessagesPos() {
        this.chatMessages.y = this.chatBar.y - this.chatMessages.height;
    }

    setChatBarVisibility(visibility) {
        this.chatBar.visible = visibility;
        this.chatMessages.visible = visibility;
        document.getElementById('chat-input').style.display = visibility? 'inline-block': 'none';
    }

    show(flow, onClose, ...args) {
        if (!UIManager.FLOW_COMPONENTS[flow]) return;
        if (this.currentElement) this.removeChild(this.currentElement);
        this.currentFlow = flow;
        this.setChatBarVisibility(false);

        this.currentElement = new UIManager.FLOW_COMPONENTS[flow](...args);
        this.addChild(this.currentElement);

        const onFinish = (...args) => {
            this.removeChild(this.currentElement);
            this.currentElement = null;
            this.currentFlow = null;
            this.setChatBarVisibility(true);
            if (onClose) onClose(...args);
        }
        this.currentElement.onComplete = onFinish.bind(this);
    }

    showDialog(text, showNext = true, onNext = null) {
        this.currentElement = new Dialog(text, showNext);
        this.setChatBarVisibility(false);

        const onClose = () => {
            this.removeChild(this.currentElement);
            this.currentElement = null;
            this.setChatBarVisibility(true);
            if (onNext) onNext();
        }
        this.currentElement.onNext = onClose.bind(this);
        this.addChild(this.currentElement);
    }

    showDialogWithOption(text, onNext, onBack) {
        // Create a new Dialog instance with both next and back buttons
        this.currentElement = new Dialog(text, true, true);
        this.setChatBarVisibility(false);

        // Define the onClose callback for the next button
        const onNextCallback = () => {
            this.removeChild(this.currentElement);
            this.currentElement = null;
            this.setChatBarVisibility(true);
            if (onNext) onNext();
        };

        // Define the onClose callback for the back button
        const onBackCallback = () => {
            this.removeChild(this.currentElement);
            this.currentElement = null;
            this.setChatBarVisibility(true);
            if (onBack) onBack();
        };

        // Assign the callbacks to the Dialog instance
        this.currentElement.onNext = onNextCallback.bind(this);
        this.currentElement.onBack = onBackCallback.bind(this);

        // Add the Dialog instance to the container
        this.addChild(this.currentElement);
    }


    hideCurrentElement(){
        this.removeChild(this.currentElement);
        this.currentElement = null;
        this.setChatBarVisibility(true);
    }

    isShowingUI() {
        return this.currentElement !== null;
    }
}

export default UIManager;