class Sound {
    constructor() {
        this.bgMusicTracks = {};
        this.soundEffects = {};
        this.currentTrackName = null;
        this.isPlaying = false;
        this.onLoaded = null;
    }

    /**
     * Preloads multiple background music tracks and auto-plays the default one.
     * @param {Object} tracks - An object with track names as keys and URLs as values.
     * @param {string} defaultTrackName - The name of the default track to play.
     */
    preloadBGMusic(tracks, defaultTrackName) {
        for (const [name, url] of Object.entries(tracks)) {
            const audio = new Audio(url);
            audio.loop = true;

            if (name === defaultTrackName) {
                audio.addEventListener('canplaythrough', () => {
                    if(this.onLoaded) this.onLoaded();
                }, { once: true });
            }

            this.bgMusicTracks[name] = audio;
        }

        this.currentTrackName = defaultTrackName;
    }

    /**
     * Plays the specified background music track.
     * @param {string} trackName - The name of the track to play.
     */
    playBGMusic(trackName) {
        if (this.isPlaying) this.stopBGMusic();
        const trackToPlay = this.bgMusicTracks[trackName];
        if (trackToPlay && !this.isPlaying) {
            trackToPlay.play().catch(e => {
                console.error("Error playing background music:", e);
                this.isPlaying = false;
            });
            this.isPlaying = true;
            this.currentTrackName = trackName;
        }
    }

    /**
     * Stops the current background music.
     */
    stopBGMusic() {
        const currentTrack = this.bgMusicTracks[this.currentTrackName];
        if (currentTrack) {
            currentTrack.pause();
            currentTrack.currentTime = 0;
            this.isPlaying = false;
        }
    }

    /**
     * Preloads sound effects.
     * @param {Object} effects - An object with effect names as keys and URLs as values.
     */
    preloadSFX(effects) {
        for (const [name, url] of Object.entries(effects)) {
            const audio = new Audio(url);
            this.soundEffects[name] = audio;
        }
    }

    /**
     * Plays a sound effect.
     * @param {string} effectName - The name of the sound effect to play.
     */
    playSFX(effectName) {
        const effect = this.soundEffects[effectName];
        if (effect) {
            const effectInstance = effect.cloneNode(); // Clone to allow overlapping effects
            effectInstance.play().catch(e => {
                console.error("Error playing sound effect:", e);
            });
        } else {
            console.warn(`Sound effect ${effectName} not found.`);
        }
    }
}

export default Sound;
