// AssetLoader.js
import * as PIXI from 'pixi.js';

const AssetLoader = {
    loader: new PIXI.Loader(),
    assets: [
        { name: 'lab', url: 'assets/map/lab.png' },
        { name: 'level1', url: 'assets/map/level1.png' },
        { name: 'playerDown1', url: 'assets/player/player_down1.png' },
        { name: 'playerDown2', url: 'assets/player/player_down2.png' },
        { name: 'playerDown3', url: 'assets/player/player_down3.png' },
        { name: 'playerLeft1', url: 'assets/player/player_left1.png' },
        { name: 'playerLeft2', url: 'assets/player/player_left2.png' },
        { name: 'playerLeft3', url: 'assets/player/player_left3.png' },
        { name: 'playerRight1', url: 'assets/player/player_right1.png' },
        { name: 'playerRight2', url: 'assets/player/player_right2.png' },
        { name: 'playerRight3', url: 'assets/player/player_right3.png' },
        { name: 'playerUp1', url: 'assets/player/player_up1.png' },
        { name: 'playerUp2', url: 'assets/player/player_up2.png' },
        { name: 'playerUp3', url: 'assets/player/player_up3.png' },
        { name: 'bigMenuEmpty', url: 'assets/ui/big-menu-empty.png' },
        { name: 'dialogBg', url: 'assets/ui/dialog-bg.png' },
        { name: 'chatBar', url: 'assets/ui/chat-bar.png' },
        { name: 'battleBg', url: 'assets/battle/battle-bg2.png' },
        { name: 'healthBarOverlay', url: 'assets/battle/health-bar-overlay.png' },
        { name: 'healthBarFilling', url: 'assets/battle/health-bar-filling.png' },
        { name: 'creatureFrame', url: 'assets/battle/creature-frame.png' },
        { name: 'versusIcon', url: 'assets/battle/versus.png' },
        { name: 'logo', url: 'assets/battle/logo.png' },
        { name: 'battleBarFilling', url: 'assets/battle/battle-bar-filling.png' },
        { name: 'battleBarOverlay', url: 'assets/battle/battle-bar-overlay.png' },
        { name: 'battleBarShield', url: 'assets/battle/battle-bar-shield.png' },
        { name: 'battleBarSword', url: 'assets/battle/battle-bar-sword.png' },
        { name: 'battleSpacebar', url: 'assets/battle/spacebar.png' },
        { name: 'rival', url: 'assets/etc/rival.png' },
        { name: 'rivalCreature', url: 'https://creaturesgame.s3.us-west-1.amazonaws.com/creatures-2.png' },
    ],

    loadAll(completeCallback) {
        this.loader.reset();

        this.assets.forEach(asset => {
            this.loader.add(asset.name, asset.url);
        });

        this.loader.load(completeCallback);
    },

    /**
     * Adds new assets to the loader and loads them.
     * @param {Object[]} newAssets - An array of new assets to load.
     * @param {Function} onLoaded - Callback function to execute after assets are loaded.
     */
    addAndLoadNewAssets(newAssets, onLoaded) {
        // Check if there are new assets to load
        if (!newAssets || newAssets.length === 0) {
            onLoaded();
            return;
        }

        // Add new assets to the loader
        newAssets.forEach(asset => {
            if (!this.loader.resources[asset.name]) {
                this.loader.add(asset.name, asset.url);
            }
        });

        // Load the new assets
        this.loader.load(() => {
            // After loading new assets, call the onLoaded callback
            onLoaded();
        });
    }
};

export default AssetLoader;
