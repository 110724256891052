import * as PIXI from 'pixi.js';
import Global from '../../../Global';

/**
 * The Dialog class extends PIXI.Container to create a dialog element with text.
 */
class Dialog extends PIXI.Container {
    /**
     * Constructs a new Dialog instance.
     * @param {string} text - The text to display on the dialog.
     * @param {boolean} [showNextBtn=false] - Whether to show the next button.
     * @param {boolean} [showBackBtn=false] - Whether to show the back button.
     */
    constructor(text, showNextBtn = true, showBackBtn = false) {
        super();
        this.dialogSprite = null;
        this.dialogText = text;
        this.showNextBtn = showNextBtn;
        this.showBackBtn = showBackBtn;
        this.onNext = null; // Callback for the next button
        this.onBack = null; // Callback for the back button

        this.loadDialog();
        this.addText();
        if (this.showNextBtn) {
            this.addNextButton();
        }
        if (this.showBackBtn) {
            this.addBackButton();
        }
    }

    /**
     * Loads the dialog image and adds it as a sprite.
     */
    loadDialog() {
        const dialogTexture = PIXI.Texture.from('assets/ui/dialog-bg.png');

        this.dialogSprite = new PIXI.Sprite(dialogTexture);
        this.dialogSprite.scale.x = 0.67;
        this.dialogSprite.scale.y = 0.67;
        this.x = (Global.width-this.dialogSprite.width)/2;
        this.y = (Global.height-this.dialogSprite.height) - 5;

        this.addChild(this.dialogSprite);
    }

    /**
     * Adds text on top of the dialog box.
     */
    addText() {
        this.textObj = new PIXI.Text('', { // Initially set the text to empty
            fontFamily: 'Press Start 2P',
            fontSize: 16,
            lineHeight: 28,
            fill: 0xFFFFFF,
            align: 'left'
        });

        this.textObj.x = 50;
        this.textObj.y = 50;

        this.addChild(this.textObj);

        this.typeText(); // Start the typing animation
    }

    /**
     * Animates the dialog text as if it is being typed out.
     * Each character of the dialog text is added one by one at a defined interval.
     */
    typeText() {
        const typingSpeed = 20; // Milliseconds between each character
        let charIndex = 0; // Current character index

        const typingInterval = setInterval(() => {
            if (charIndex < this.dialogText.length) {
                this.textObj.text += this.dialogText[charIndex];
                charIndex++;
            } else {
                clearInterval(typingInterval); // Stop the typing animation when done
            }
        }, typingSpeed);
    }

    /**
     * Adds a next button to the dialog box.
     * @private
     */
    addNextButton() {
        // Create a simple next button (customize as needed)
        this.nextButton = new PIXI.Text('NEXT', {
            fontFamily: 'Press Start 2P',
            fontSize: 16,
            fill: 0xFFFFFF
        });

        // Position the button at the bottom-right of the dialog box
        this.nextButton.x = this.dialogSprite.width - this.nextButton.width - 50;
        this.nextButton.y = this.dialogSprite.height - this.nextButton.height - 30;
        this.nextButton.interactive = true;
        this.nextButton.buttonMode = true;
        this.nextButton.on('pointerdown', () => {
            Global.game.sound.playSFX('dialog-continue');
            if (this.onNext) {
                this.onNext();
            }
        });

        this.addChild(this.nextButton);
    }

    /**
     * Adds a back button to the dialog box.
     * @private
     */
    addBackButton() {
        this.backButton = new PIXI.Text('BACK', {
            fontFamily: 'Press Start 2P',
            fontSize: 16,
            fill: 0xFFFFFF
        });

        this.backButton.x = 50; // Positioning the button on the left side
        this.backButton.y = this.dialogSprite.height - this.backButton.height - 30;
        this.backButton.interactive = true;
        this.backButton.buttonMode = true;
        this.backButton.on('pointerdown', () => {
            Global.game.sound.playSFX('dialog-continue');
            if (this.onBack) {
                this.onBack();
            }
        });

        this.addChild(this.backButton);
    }

    /**
     * Sets the visibility of the next button.
     * @param {boolean} visible - Whether the next button should be visible.
     */
    setNextBtn(visible) {
        if (visible && !this.nextButton) {
            // Create and add the next button if it doesn't exist
            this.addNextButton();
        } else if (this.nextButton) {
            // Update the visibility of the existing next button
            this.nextButton.visible = visible;
        }
    }
}

export default Dialog;
