import * as PIXI from 'pixi.js';
import Dialog from "../components/Dialog";
import DialogChain from "../components/DialogChain";

class CreatingCreatureFlow extends PIXI.Container {
    /**
     * Creates an instance of CreatingCreatureFlow.
     * @param {string[]} dialogTexts - Array of strings for the dialog chain.
     */
    constructor(dialogTexts) {
        super();

        // Instantiate a DialogChain with provided dialog texts
        this.dialogChain = new DialogChain(dialogTexts, true);
        this.dialogChain.onFinish = this.showHatchingDialog.bind(this);

        this.addChild(this.dialogChain);
    }

    /**
     * Shows the "Hatching..." dialog when the dialog chain finishes.
     * @private
     */
    showHatchingDialog() {
        // Remove the dialog chain
        this.removeChild(this.dialogChain);

        // Add a new dialog with "Hatching..." text
        const hatchingDialog = new Dialog('GPT is taking a second...\nAlmost done', false, false);
        this.addChild(hatchingDialog);
    }
}

export default CreatingCreatureFlow;
