import * as PIXI from 'pixi.js';
import AssetLoader from '../../AssetLoader';

class CreatureFrame extends PIXI.Container {
    /**
     * Constructs a new CreatureFrame instance.
     * @param {string} creatureUrl - The URL to the creature's image.
     * @param {string} direction - The direction of the creature ('left' or 'right').
     */
    constructor(creatureUrl, direction = 'left') {
        super();
        this.creatureUrl = creatureUrl;
        this.direction = direction;

        // Define assets to be loaded
        const nameWithUrls = [
            { name: 'creatureImage', url: this.creatureUrl }
        ];

        // Load the assets and bind the onImagesLoaded callback
        AssetLoader.addAndLoadNewAssets(nameWithUrls, this.onImagesLoaded.bind(this));
    }

    /**
     * Callback for when images are loaded.
     * @param {Object} resources - The loaded resources.
     */
    onImagesLoaded(resources) {
        // Add the creature image
        const creatureTexture = PIXI.Texture.from(this.creatureUrl);
        const creatureSprite = new PIXI.Sprite(creatureTexture);

        this.addChild(creatureSprite);
        creatureSprite.scale.x = creatureSprite.scale.y = 0.19;
        creatureSprite.x = 25;
        creatureSprite.y = 25;

        // If direction is 'right', flip the creature image
        if (this.direction === 'right') {
            creatureSprite.scale.x *= -1;
            creatureSprite.anchor.x = 1; // Ensure the image is still aligned after flipping
        }

        // Add the frame image
        const frameTexture = PIXI.Texture.from('assets/battle/creature-frame.png');
        const frameSprite = new PIXI.Sprite(frameTexture);
        this.addChild(frameSprite);
        frameSprite.scale.x = frameSprite.scale.y = 0.26;
    }

    /**
     * Does an attack animation
     */
    attack() {
        // Define the attack movement distance
        const movementDistance = 20; // Adjust as needed for the desired effect
        const originalX = this.x; // Store the original position
        const attackDirection = this.direction === 'left' ? -1 : 1; // Determine attack direction based on creature's facing direction
        const targetX = originalX + (movementDistance * attackDirection); // Calculate target position

        const speed = 2; // Speed of the attack animation
        const returnSpeed = 1; // Speed of the return animation
        let isAttacking = true; // Flag to track attack state

        const animateAttack = (delta) => {
            if (isAttacking) {
                // Move forward
                this.x += speed * attackDirection * delta;
                if ((attackDirection === 1 && this.x >= targetX) || (attackDirection === -1 && this.x <= targetX)) {
                    isAttacking = false; // Switch to return phase
                }
            } else {
                // Return to original position
                this.x -= returnSpeed * attackDirection * delta;
                if ((attackDirection === 1 && this.x <= originalX) || (attackDirection === -1 && this.x >= originalX)) {
                    this.x = originalX; // Ensure it ends exactly at the original position
                    PIXI.Ticker.shared.remove(animateAttack); // Stop the animation
                }
            }
        };

        PIXI.Ticker.shared.add(animateAttack);
    }

    /**
     * Makes the creature blink rapidly for a second.
     */
    blink() {
        const blinkDuration = 1000; // Blink duration in milliseconds
        const blinkInterval = 50; // Interval between blinks in milliseconds
        let elapsed = 0; // Time elapsed since blinking started

        // Store the original alpha state
        const originalAlpha = this.alpha;

        const blinkAnimation = (delta) => {
            elapsed += PIXI.Ticker.shared.elapsedMS;

            // Toggle alpha at each interval
            if (Math.floor(elapsed / blinkInterval) % 2 === 0) {
                this.alpha = 0.5;
            } else {
                this.alpha = 1;
            }

            // Stop the animation after the blink duration
            if (elapsed >= blinkDuration) {
                this.alpha = originalAlpha; // Ensure the creature's alpha is reset at the end
                PIXI.Ticker.shared.remove(blinkAnimation);
            }
        };

        PIXI.Ticker.shared.add(blinkAnimation);
    }
}

export default CreatureFrame;
