import * as PIXI from 'pixi.js';

import lapMapData from './data/map-lab';
import homeMapData from './data/map-home';
import Rival from "./Rival";
import Utils from "../../Utils";

/**
 * The Map class extends PIXI.Container to create a map element.
 */
class Map extends PIXI.Container {
    static MapCodes = {
        LAB: 0,
        HOME: 1,
    }
    // Map each code to a file path
    static MapData = {
        [Map.MapCodes.LAB]: lapMapData,
        [Map.MapCodes.HOME]: homeMapData,
    };

    constructor() {
        super();
        this.mapSprite = null;
        this.hitBoxGraphics = [];
        this.portalBoxGraphics = [];
        this.currentMapCode = null;
        this.rival = null;
        this.changeMap(Map.MapCodes.HOME); // Initialize with default map
    }

    /**
     * Changes the map based on the provided map code.
     * @param {number} mapCode - The code representing the map to load.
     */
    changeMap(mapCode) {
        this.currentMapCode = mapCode;
        // Remove the current map sprite if it exists
        if (this.mapSprite) {
            this.removeChild(this.mapSprite);
            this.mapSprite.destroy();
            this.mapSprite = null;
        }

        if (this.rival) {
            this.removeChild(this.rival);
            this.rival = null;
        }

        // Load the new map texture
        const filePath = Map.MapData[mapCode].asset;
        if (!filePath) {
            console.error(`Map file path not found for code: ${mapCode}`);
            return;
        }

        const newMapTexture = PIXI.Texture.from(filePath);

        this.mapSprite = new PIXI.Sprite(newMapTexture);
        this.addChild(this.mapSprite);

        // add rival if needed
        if (Map.MapData[mapCode].rival) {
            this.rival = new Rival();
            this.addChild(this.rival);
            this.rival.x = Map.MapData[mapCode].rival.x;
            this.rival.y = Map.MapData[mapCode].rival.y;
        }

        if (Map.MapData[mapCode].hitBoxes) {
            // this.drawHitBoxes(Map.MapData[mapCode].hitBoxes);
        }

        // portal boxes ===========================
        if (Map.MapData[mapCode].portals) {
            // this.drawPortalBoxes(Map.MapData[mapCode].portals);
        }
    }

    /**
     * Draws hit boxes on the map.
     * @param {Array<{x: number, y: number, width: number, height: number}>} hitBoxes - List of hit boxes.
     */
    drawHitBoxes(hitBoxes) {
        // Clear existing hit boxes
        this.hitBoxGraphics.forEach(graphic => {
            this.removeChild(graphic);
            graphic.destroy();
        });
        this.hitBoxGraphics = [];
        hitBoxes.forEach(box => {
            const graphics = new PIXI.Graphics();
            graphics.beginFill(0x000000, 0.5); // Black color for hit box
            graphics.drawRect(box.x, box.y, box.width, box.height);
            graphics.endFill();
            this.addChild(graphics);
            this.hitBoxGraphics.push(graphics); // Store the graphics object
        });
    }

    /**
     * Draws portal boxes on the map.
     * @param {Array<{x: number, y: number, width: number, height: number}>} hitBoxes - List of hit boxes.
     */
    drawPortalBoxes(portalBoxes) {
        // Clear existing portal boxes
        this.portalBoxGraphics.forEach(graphic => {
            this.removeChild(graphic);
            graphic.destroy();
        });
        this.portalBoxGraphics = [];
        portalBoxes.forEach(box => {
            const graphics = new PIXI.Graphics();
            graphics.beginFill(0x0000FF, 0.5); // Black color for hit box
            graphics.drawRect(box.x, box.y, box.width, box.height);
            graphics.endFill();
            this.addChild(graphics);
            this.portalBoxGraphics.push(graphics); // Store the graphics object
        });
    }

    /**
     * Checks if a given rectangle collides with any of the hit boxes.
     * @param {number} x - The x coordinate of the rectangle.
     * @param {number} y - The y coordinate of the rectangle.
     * @param {number} width - The width of the rectangle.
     * @param {number} height - The height of the rectangle.
     * @returns {boolean} True if there's a collision, false otherwise.
     */
    collidesWithHitBoxes(x, y, width, height) {
        const rect1 = { x, y, width, height };

        const currentMapData = Map.MapData[this.currentMapCode];
        if (!currentMapData || !currentMapData.hitBoxes) {
            return false;
        }

        return currentMapData.hitBoxes.some(hitBox => {
            const rect2 = hitBox;
            return Utils.rectsIntersect(rect1, rect2);
        });
    }

    /**
     * Checks for collision with portal areas and returns the map code of the collided portal.
     * @param {number} x - The x coordinate of the object to check for collisions.
     * @param {number} y - The y coordinate of the object to check for collisions.
     * @param {number} width - The width of the object to check for collisions.
     * @param {number} height - The height of the object to check for collisions.
     * @returns {number|null} - Returns the map code of the portal if a collision is detected, otherwise returns null.
     */
    getCollidingPortalMapCode(x, y, width, height) {
        const rect1 = { x, y, width, height };
        const currentMapData = Map.MapData[this.currentMapCode];

        if (!currentMapData || !currentMapData.portals) {
            return null;
        }

        const portal = currentMapData.portals.find(portal => {
            const rect2 = portal;
            return Utils.rectsIntersect(rect1, rect2);
        });

        return portal ? portal.mapCode : null;
    }

    collidingWithRival(x, y, width, height) {
        const rect1 = { x, y, width, height };
        const rect2 = {
            x: this.rival.x,
            y: this.rival.y+this.rival.height/2,
            width: 18,
            height: 15
        };

        // Check for intersection
        return Utils.rectsIntersect(rect1, rect2);
    }
}

export default Map;
