import * as PIXI from 'pixi.js';
import Character from './Character';
import Map from './maps/Map';
import Global from "../Global";

class World extends PIXI.Container {
    constructor() {
        super();

        this.char = new Character();
        this.map = new Map();
        this.addChild(this.map);
        this.addChild(this.char);

        this.targetX = 0;
        this.targetY = 0;

        // Store the reference to the bound update function
        this.boundUpdate = this.update.bind(this);
        PIXI.Ticker.shared.add(this.boundUpdate);
    }

    /**
     * Updates the position of the world to center the character.
     */
    update() {
        // Center the world on the character
        this.targetX = -this.char.x + Global.width / 2;
        this.targetY = -this.char.y + Global.height / 2;

        this.x += (this.targetX - this.x) / 15;
        this.y += (this.targetY - this.y - 25) / 15;

        // Optional: Add bounds to prevent seeing outside the map
        this.x = Math.min(this.x, 0);
        this.y = Math.min(this.y, 0);
        // this.x = Math.max(this.x, -this.map.width + this.screenWidth);
        // this.y = Math.max(this.y, -this.map.height + this.screenHeight);
    }

    /**
     * Cleans up resources when the world is no longer needed.
     */
    destroy() {
        // Remove the update function from the ticker
        PIXI.Ticker.shared.remove(this.boundUpdate);

        // Optionally, call destroy on children if they also need cleanup
        this.char.destroy();
        this.map.destroy();

        super.destroy();
    }
}

export default World;
