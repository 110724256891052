import * as PIXI from 'pixi.js';
import Global from '../../../Global';

/**
 * The ChatBar class extends PIXI.Container to create a chat bar element with a send button.
 */
class ChatBar extends PIXI.Container {
    constructor() {
        super();
        this.loadBackground();
        this.addSendButton();
    }

    /**
     * Loads the chat bar background image and adds it as a sprite.
     */
    loadBackground() {
        const chatBarTexture = PIXI.Texture.from('assets/ui/chat-bar.png');

        this.chatBarSprite = new PIXI.Sprite(chatBarTexture);
        this.chatBarSprite.scale.x = 1; // Adjust the scale as needed
        this.chatBarSprite.scale.y = 1; // Adjust the scale as needed
        this.x = (Global.width - this.chatBarSprite.width) / 2;
        this.y = Global.height - this.chatBarSprite.height - 5;

        this.addChild(this.chatBarSprite);
    }

    /**
     * Adds a send button to the chat bar.
     */
    addSendButton() {
        this.sendButton = new PIXI.Text('SEND', {
            fontFamily: 'Arial',
            fontSize: 16,
            fill: 0 // White color
        });

        // Position the button at the bottom-right of the chat bar
        this.sendButton.x = this.chatBarSprite.width - this.sendButton.width - 25;
        this.sendButton.y = (this.chatBarSprite.height - this.sendButton.height) / 2;
        this.sendButton.interactive = true;
        this.sendButton.buttonMode = true;
        this.sendButton.on('pointerdown', () => {
            Global.game.sendChat();
        });

        this.addChild(this.sendButton);
    }
}

export default ChatBar;
