import Map from '../Map';

const mapData = {
    asset: 'assets/map/level1.png',
    hitBoxes: [
        // lab
        { x: 1092, y: 565, width: 295, height: 30 },
        { x: 1172, y: 538, width: 115, height: 30 },
        { x: 1092, y: 690, width: 188, height: 110 },
        { x: 1125, y: 590, width: 175, height: 110 },
        { x: 1328, y: 680, width: 70, height: 120 },
        { x: 1275, y: 640, width: 75, height: 40 },
        // walls
        { x: 0, y: 0, width: 410, height: 1000 }, // left wall
        { x: 0, y: 880, width: 2000, height: 40 }, // bottom wall
        { x: 1500, y: 0, width: 2000, height: 2000 }, // right wall
        { x: 0, y: 0, width: 2000, height: 240 }, // top wall
        // house above lab
        { x: 1092, y: 260, width: 230, height: 200 },
        // top left house
        { x: 550, y: 260, width: 280, height: 200 },
        { x: 490, y: 220, width: 390, height: 90 },
        { x: 510, y: 450, width: 140, height: 50 },
        {x: 745, y: 450, width: 125, height: 50 }
    ],
    portals: [
        { x: 1275, y: 735, width: 50, height: 25, mapCode:  0}, // TODO: use constant instead for mapCode
    ],
    rival: {
        x: 920,
        y: 230
    }
};

export default mapData;
