import React, {useEffect, useState} from 'react';
import './Login.css';
import CreatureAPI from "../API";
import {useLocation, useNavigate} from "react-router-dom";
import CreatureStorage from "../game/CreatureStorage";

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [isEmailSubmitted, setEmailSubmitted] = useState(false);
    const [continueBtnEnabled, setContinueBtnEnabled] = useState(true);
    const [verifyBtnEnabled, setVerifyBtnEnabled] = useState(true);
    const [verificationCode, setVerificationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isResending, setIsResending] = useState(false);
    const [countdown, setCountdown] = useState(60);

    const getReferralParam = () => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get('r');
    };

    const referral = getReferralParam();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    };

    const handleSubmitEmail = async () => {
        // Regular expression for validating an email
        const emailRegex = /^[^\s@+]+@[^\s@]+\.[^\s@]+$/;

        // Validate the email using the regex
        if (emailRegex.test(email)) {
            try {
                setContinueBtnEnabled(false);
                await CreatureAPI.initiateLogin(email);
                setEmailSubmitted(true);
                setErrorMessage(null);
            } catch (error) {
                setErrorMessage(error.message || 'Failed to send verification code.');
            }
        } else {
            // If email is not valid, set an error message
            setErrorMessage('Please enter a valid email address.');
        }
        setContinueBtnEnabled(true);
    };

    const handleSubmitVerificationCode = async () => {
        if (verificationCode.length === 5) { // Assuming a 5-character code
            try {
                setVerifyBtnEnabled(false);
                const response = await CreatureAPI.verifyLogin(email, verificationCode, referral);

                CreatureStorage.setToken(response.token);
                CreatureStorage.setCreatedCreature(false);
                CreatureStorage.setFoughtRival(false);

                window.location.href = '/game';
            } catch (error) {
                setErrorMessage(error.message || 'Verification failed.');
            }
            setVerifyBtnEnabled(true);
        }
    };

    const handleResendCode = async () => {
        setIsResending(true);
        setCountdown(60); // Reset countdown to 60 seconds

        try {
            await CreatureAPI.initiateLogin(email);
            // Optionally reset or keep the current verification code value
        } catch (error) {
            setErrorMessage(error.message || 'Failed to resend verification code.');
            setIsResending(false); // Enable the button if there's an error
        }
    };

    useEffect(() => {
        let interval = null;

        if (isResending && countdown > 0) {
            interval = setInterval(() => {
                setCountdown((currentCountdown) => currentCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsResending(false);
            clearInterval(interval);
        }

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [isResending, countdown]);

    return (
        <div className="login-background">
            <div className="login-container">
                <h2>{isEmailSubmitted ? 'Verify Email' : "What's your email?"}</h2>
                <p className="login-info">
                    {isEmailSubmitted
                        ? 'We emailed you a verification code. Enter it below.'
                        : 'This will create an account or login to an existing one.'}
                </p>

                {errorMessage && <p className="error-message">{errorMessage}</p>}

                {isEmailSubmitted ? (
                    <input
                        type="text"
                        maxLength={5}
                        placeholder="Enter verification code"
                        value={verificationCode}
                        onChange={handleVerificationCodeChange}
                    />
                ) : (
                    <input
                        type="text"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                )}

                {isEmailSubmitted ? (
                    <>
                        <button
                            className="resend-code-button"
                            onClick={handleResendCode}
                            disabled={isResending}
                        >
                            {isResending ? `Try again in ${countdown} seconds...` : 'Resend code'}
                        </button>
                        <button
                            className="continue-button"
                            onClick={handleSubmitVerificationCode}
                            disabled={verificationCode.length !== 5 || !verifyBtnEnabled}>
                            Verify
                        </button>
                    </>
                ) : (
                    <button
                        className="continue-button"
                        onClick={handleSubmitEmail}
                        disabled={!continueBtnEnabled}
                    >
                        Continue
                    </button>
                )}
            </div>
        </div>
    );
}

export default LoginPage;
