import * as PIXI from 'pixi.js';

class ChatMessages extends PIXI.Container {
    constructor() {
        super();
        this.messages = [];
        this.maxMessages = 10;
        this.messageWidth = 640;
        this.messageHeight = 20; // Adjust as needed
    }

    addChat(msg) {
        // Create a new message
        const message = new PIXI.Text(msg, {
            fontFamily: 'Arial',
            fontSize: 14,
            fill: 0xFFFFFF,
            wordWrap: true,
            wordWrapWidth: this.messageWidth
        });

        const background = new PIXI.Graphics();
        background.beginFill(0x000000, 0.5); // Black background
        background.drawRect(0, 0, this.messageWidth, this.messageHeight);
        background.endFill();

        const messageContainer = new PIXI.Container();
        messageContainer.addChild(background);
        messageContainer.addChild(message);
        message.y = (this.messageHeight - message.height) / 2; // Vertically center text
        message.x = 5;

        // Position the new message at the bottom
        messageContainer.y = this.messages.reduce((acc, curr) => acc + curr.height, 0);

        this.addChild(messageContainer);
        this.messages.push(messageContainer);

        // Remove the oldest message if exceeding maxMessages
        if (this.messages.length > this.maxMessages) {
            const oldestMessage = this.messages.shift();
            this.removeChild(oldestMessage);
        }

        // Update the position of existing messages
        this.updateMessagesPosition();

        // Set a timeout to remove the message after 10 seconds
        setTimeout(() => {
            this.removeChild(messageContainer);
            this.messages = this.messages.filter(msg => msg !== messageContainer);
            this.updateMessagesPosition();
        }, 10000);
    }

    updateMessagesPosition() {
        let accumulatedHeight = 0;
        for (let i = 0; i < this.messages.length; i++) {
            const msg = this.messages[i];
            msg.y = accumulatedHeight;
            accumulatedHeight += msg.height;
        }
        this.onHeightUpdated();
    }

    onHeightUpdated() {
    }
}

export default ChatMessages;
