import * as PIXI from 'pixi.js';
import Global from "../../../Global";

/**
 * The BigChoicePicker class extends PIXI.Container to create a menu with clickable choice buttons.
 */
class CreatureOptionPicker extends PIXI.Container {
    /**
     * Constructs a new BigChoicePicker instance.
     * @param {string} title - The title of the picker.
     * @param {string[]} choices - An array of choice strings.
     * @param {boolean} [showBack=false] - Whether to show the back button.
     * @param {boolean} [showNext=false] - Whether to show the next button.
     * @param {boolean} [available=null] - Object of species options availabilities
     * @param {boolean} [prefix=''] - i.e 'Fire' if fire has been picked, 'FireDragon'
     */
    constructor(title, choices, showBack = false, showNext = false, available = null, prefix = '') {
        super();
        this.title = title;
        this.choices = choices;
        this.showBack = showBack;
        this.showNext = showNext;
        this.onChoiceSelected = null; // Callback for choice selection
        this.available = available;
        this.prefix = prefix;

        this.loadBackground();
        this.addTitle();
        this.addChoiceButtons();
        if (this.showBack) {
            this.addBackButton();
        }
        if (this.showNext) {
            this.addNextButton();
        }

        // center menu
        this.x = (Global.width-this.width)/2;
        this.y = (Global.height-this.height)/2;
    }

    /**
     * Loads the background image and adds it as a sprite.
     */
    loadBackground() {
        const bgTexture = PIXI.Texture.from('assets/ui/big-menu-empty.png');
        const bgSprite = new PIXI.Sprite(bgTexture);
        this.addChild(bgSprite);

        bgSprite.scale.x = bgSprite.scale.y = 0.55;
    }

    /**
     * Adds the title text at the top of the menu.
     */
    addTitle() {
        const titleText = new PIXI.Text(this.title, {
            fontFamily: 'Press Start 2P',
            fontSize: 25,
            fill: 0xFFFFFF
        });
        titleText.x = (this.width - titleText.width) / 2;
        titleText.y = 25;
        this.addChild(titleText);
    }

    /**
     * Adds choice buttons to the menu.
     */
    addChoiceButtons() {
        const startY = 100; // Starting Y position for choices
        const spacing = 50; // Vertical spacing between choices
        this.choices.forEach((choice, index) => {
            const count = this.available[this.prefix+choice+'Available'];
            const choiceButton = this.createChoiceButton(count, choice);
            choiceButton.x = (this.width - choiceButton.width) / 2;
            choiceButton.y = startY + (index * spacing);
            this.addChild(choiceButton);
        });
    }

    /**
     * Creates a choice button.
     * @param {Number} count - # available
     * @param {string} choice - The text for the choice.
     * @return {PIXI.Text} The created choice button.
     */
    createChoiceButton(count, choice) {
        const button = new PIXI.Text(`${count ?? ''} ${choice}`, {
            fontFamily: 'Press Start 2P',
            fontSize: 18,
            fill: 0xFFFFFF
        });
        if (count > 0 || count == null) {
            button.interactive = true;
            button.buttonMode = true;
            button.on('pointerdown', () => {
                if (this.onChoiceSelected) {
                    this.onChoiceSelected(choice);
                }
            });
        }

        return button;
    }

    /**
     * Adds a back button at the bottom of the menu.
     */
    addBackButton() {
        const backButton = this.createButton('BACK');
        backButton.x = 50;
        backButton.y = this.height - backButton.height - 30;
        this.addChild(backButton);

        backButton.on('pointerdown', () => {
            if (this.onBack) {
                this.onBack();
            }
        });
    }

    /**
     * Adds a next button at the bottom of the menu.
     */
    addNextButton() {
        const nextButton = this.createButton('NEXT');
        nextButton.x = this.width - nextButton.width - 50;
        nextButton.y = this.height - nextButton.height - 30;
        this.addChild(nextButton);
    }

    /**
     * Creates a text button.
     * @param {string} buttonText - The text for the button.
     * @return {PIXI.Text} The created button.
     */
    createButton(buttonText) {
        const button = new PIXI.Text(buttonText, {
            fontFamily: 'Press Start 2P',
            fontSize: 16,
            fill: 0xFFFFFF
        });
        button.interactive = true;
        button.buttonMode = true;
        // Add any event listeners here
        return button;
    }
}

export default CreatureOptionPicker;
